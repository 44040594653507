import React from "react";

interface IProps {
  isChecked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  hasError?: boolean;
  type?: string;
  label: string;
  errMsg?: string;
}
const AgreeToTermsCheckbox = ({
  isChecked,
  hasError,
  onChange,
  label,
  errMsg,
}: IProps) => {
  return (
    <div className="mt-2 flex flex-col">
      <div className="flex items-center">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={onChange}
          className="h-5 w-5 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500"
        />
        <div
          className="dangerouslySetInnerHTML ml-[10px] text-left text-base text-blue-dark"
          dangerouslySetInnerHTML={
            { __html: label || "" } as {
              __html: string;
            }
          }
        />
      </div>
      {hasError && errMsg ? (
        <div className="pl-4 text-red">{errMsg}</div>
      ) : (
        <div className="h-6"></div>
      )}
    </div>
  );
};

export default AgreeToTermsCheckbox;
