import React from "react";

const InstagramSvg = () => {
    return (
        <svg width="70" height="72" viewBox="0 0 70 72" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M28.9823 20.7344H41.2198C45.9312 20.7344 49.786 24.5892 49.786 29.3006V41.5381C49.786 46.2495 45.9312 50.1044 41.2198 50.1044H28.9823C24.2708 50.1044 20.416 46.2495 20.416 41.5381V29.3006C20.416 24.5892 24.2708 20.7344 28.9823 20.7344Z"
                fill="#0A0F3C"/>
            <path
                d="M42.6568 30.341C41.2835 30.341 40.1787 29.2361 40.1787 27.8629C40.1787 26.4896 41.2835 25.3848 42.6568 25.3848C44.0198 25.3848 45.1349 26.4896 45.1349 27.8629C45.1349 29.2361 44.0198 30.341 42.6568 30.341Z"
                fill="white"/>
            <path
                d="M35.1008 28.81C38.7457 28.81 41.7091 31.7734 41.7091 35.4182C41.7091 39.0631 38.7457 42.0265 35.1008 42.0265C31.456 42.0265 28.4926 39.0631 28.4926 35.4182C28.4926 31.7734 31.456 28.81 35.1008 28.81ZM35.1008 25.5059C29.6284 25.5059 25.1885 29.9458 25.1885 35.4182C25.1885 40.8907 29.6284 45.3306 35.1008 45.3306C40.5733 45.3306 45.0132 40.8907 45.0132 35.4182C45.0132 29.9458 40.5733 25.5059 35.1008 25.5059Z"
                fill="white"/>
        </svg>
    );
};

export default InstagramSvg;




