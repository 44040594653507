import React, { useState } from "react";
import {
  selectFieldStyle,
  selectInputStyle,
  selectOptionStyle,
} from "../../pages/Modules/CustomStyles";

interface IProps {
  id: string;
  name: string;
  value: string;
  options: { label?: string; value?: string }[];
  onChange: (e: string) => void;
  hasError?: boolean;
  placeholder: string;
  label: string;
  errMsg?: string;
}
const SelectField = ({
  id,
  name,
  value,
  onChange,
  hasError,
  options,
  placeholder,
  label,
  errMsg,
}: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const valueLabel = () => {
    const selectedVal = options.find((o) => o.value === value);
    return selectedVal?.label || placeholder;
  };

  return (
    <div className="my-2 flex flex-col">
      <div className="mb-1 flex items-start text-base font-medium text-blue-dark">
        {label}
        <div className="ml-1 text-base text-red">*</div>
      </div>
      <button
        id={id}
        name={name}
        data-dropdown-toggle="dropdown-states"
        className={`${selectFieldStyle} ${selectInputStyle} relative flex items-center justify-between`}
        type="button"
        onClick={() => setIsOpen((current) => !current)}
      >
        <span className="textEllipsis1Lines text-base">{valueLabel()}</span>
        <svg
          className="ml-2.5 h-2.5 w-2.5 text-blue"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>
      {isOpen && (
        <div
          id="dropdown-states"
          className={`${selectOptionStyle} absolute z-50 mt-20 w-[87%] xs:w-[93%]`}
        >
          <ul
            className="max-h-[360] w-full overflow-y-auto py-2 text-base text-gray-700"
            aria-labelledby="states-button"
          >
            {options?.map((o, index) => (
              <li key={index}>
                <button
                  onClick={() => {
                    o?.value && onChange(o?.value);
                    setIsOpen(false);
                  }}
                  type="button"
                  className="inline-flex w-full px-2 py-2 text-base text-gray-700 hover:bg-gray-100 md:px-4"
                >
                  {o.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {hasError && errMsg ? (
        <div className="pl-4 text-red">{errMsg}</div>
      ) : (
        <div className="h-6"></div>
      )}
    </div>
  );
};

export default SelectField;
