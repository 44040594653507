import React, { useState } from "react";
import {
  IApiResponse,
  IContentModules,
  IResourceFormValues,
} from "../../globalTypes";
import InputField from "../../components/InputField/InputField";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { submitForm } from "../ContentPage/contentApi";
import ReCAPTCHAComp from "../../components/ReCAPTCHA/ReCAPTCHAComp";
import AgreeToTermsCheckbox from "../../components/AgreeToTermsCheckbox/AgreeToTermsCheckbox";
import ResponceFormData from "../../components/ResponceForm/ResponceFormData";

interface IProps {
  data: IContentModules;
}

const validationSchema = Yup.object({
  email: Yup.string().email("Not a valid email").required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  isVerified: Yup.boolean().oneOf([true], "Required"),
  isAgreedToTerms: Yup.boolean().oneOf([true], "Required"),
});
const env = window._env_ ?? {}

const ResourceDownloadFormModule = ({ data }: IProps) => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    isVerified: false,
    isAgreedToTerms: false,
  };
  const [respData, setRespData] = useState<IApiResponse | null>(null);
  const baseUrl = env.REACT_APP_IMG_BASE_URL;

  const handleSubmit = async (
    values: IResourceFormValues,
    actions: FormikHelpers<IResourceFormValues>,
  ) => {
    actions.setSubmitting(true);

    const { firstName, lastName, email } = values;

    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    data?.resourceId &&
      formData.append("resourceId", data?.resourceId.toString());

    try {
      const status = await submitForm(formData, data?.apiUrl);

      if (status?.data) {
        setRespData(status.data);

        const newData = { ...status.data };
        if (newData?.fileLink?.url) {
          newData.fileLink.url = baseUrl + newData?.fileLink?.url;
        }
        const link = document.createElement("a");

        if (newData?.fileLink?.url) {
          link.href = newData?.fileLink?.url;
        }

        document.body.appendChild(link);
        link.click();
        link.remove();
        actions.resetForm();
        actions.setSubmitting(false);
      }
    } catch (error) {
      actions.resetForm();
      actions.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className="relative lg:min-h-[550px]">
      <div className="customRightShape"></div>
      <div className="z-20 w-full py-[30px] lg:py-[100px]">
        <div className="flex flex-col items-start justify-between px-[30px] py-6 lg:mx-auto lg:flex-row xl:w-container xl:px-0">
          <div className="z-20 my-4 h-auto w-full rounded-3xl border border-gray-light bg-white px-[16px] py-10 lg:mx-auto lg:w-1/2">
            {!respData && (
              <>
                <h2 className="pb-4 font-roboto text-2xl font-bold text-blue-dark xl:text-3xl">
                  {data?.heading}
                </h2>
                <div className="pb-4 font-roboto text-xl font-bold text-gray-secondary">
                  {data?.resourceName}
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <InputField
                    id="firstName"
                    name="firstName"
                    value={formik.values.firstName}
                    isRequired={true}
                    placeholder={data?.firstNamePlaceholder || ""}
                    label={data?.firstNameLabel || ""}
                    type="text"
                    hasError={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    errMsg={
                      formik.touched.firstName && formik.errors.firstName
                        ? formik.errors.firstName
                        : ""
                    }
                    onChange={(e) => formik.handleChange(e)}
                  />

                  <InputField
                    id="lastName"
                    name="lastName"
                    value={formik.values.lastName}
                    isRequired={true}
                    placeholder={data?.lastNamePlaceholder || ""}
                    label={data?.lastNameLabel || ""}
                    type="text"
                    hasError={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    errMsg={
                      formik.touched.lastName && formik.errors.lastName
                        ? formik.errors.lastName
                        : ""
                    }
                    onChange={(e) => formik.handleChange(e)}
                  />

                  <InputField
                    id="email"
                    name="email"
                    value={formik.values.email}
                    isRequired={true}
                    placeholder={data?.emailPlaceholder || ""}
                    label={data?.emailLabel || ""}
                    type="email"
                    hasError={
                      formik.touched.email && Boolean(formik.errors.email)
                    }
                    errMsg={
                      formik.touched.email && formik.errors.email
                        ? formik.errors.email
                        : ""
                    }
                    onChange={(e) => formik.handleChange(e)}
                  />

                  <AgreeToTermsCheckbox
                    isChecked={formik.values.isAgreedToTerms}
                    onChange={(e) =>
                      formik.setFieldValue("isAgreedToTerms", e.target.checked)
                    }
                    label={data?.agreeToTermsText || ""}
                    hasError={
                      formik.touched.isAgreedToTerms &&
                      Boolean(formik.errors.isAgreedToTerms)
                    }
                    errMsg={
                      formik.touched.isAgreedToTerms &&
                        formik.errors.isAgreedToTerms
                        ? formik.errors.isAgreedToTerms
                        : ""
                    }
                  />

                  <ReCAPTCHAComp
                    label={data?.reCaptchaLabel || ""}
                    onChange={() => formik.setFieldValue("isVerified", true)}
                    hasError={
                      formik.touched.isVerified &&
                      Boolean(formik.errors.isVerified)
                    }
                    errMsg={
                      formik.touched.isVerified && formik.errors.isVerified
                        ? formik.errors.isVerified
                        : ""
                    }
                  />

                  <button
                    type="submit"
                    className="flex w-full items-center justify-center whitespace-nowrap rounded-sm border border-blue bg-blue p-[15px] text-white hover:border-blue-hover hover:bg-blue-hover"
                  >
                    {data?.buttonText}
                  </button>
                </form>
              </>
            )}

            {respData && (
              <ResponceFormData respData={respData} setRespData={setRespData} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceDownloadFormModule;
