export const clientSaySvg = (
  <svg
    className="ml-4"
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 11L6 6L1 1"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
