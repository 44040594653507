import React, { useMemo, useState } from "react";
import { IContentModules } from "../../globalTypes";
import useMediaQuery from "../../components/hooks/useMediaQuery";
import { customModuleStyle } from "./CustomStyles";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Keyboard } from 'swiper/modules';


interface IProps {
  data: IContentModules;
}
const env = window._env_ ?? {}

const ImageSliderModule = ({ data }: IProps) => {
  const baseUrl = env.REACT_APP_IMG_BASE_URL;
  const isBigScreen = useMediaQuery("(min-width: 768px)");
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const diplayData = useMemo(() => {
    if (data?.images) {
      const newDataFormat = data?.images.map((i) => {
        return { heading: "", image: i };
      });
      return newDataFormat;
    }
    if (data?.cards) {
      return data?.cards;
    }
  }, [data]);

  const diplayHeading = useMemo(() => {
    if (data?.cards) {
      const selectedCard = data?.cards.find(
        (card, index) => index === selectedIndex,
      );
      return selectedCard ? selectedCard.heading : data.cards[0].heading;
    }
  }, [data, selectedIndex]);

  return (
    <div
      className={`${customModuleStyle(
        data?.withBackground,
      )} xl:pb-[70px] xl:pt-[50px]`}
    >
      <div className="px-10 lg:mx-auto xl:w-container xl:px-0">
        <div className="flex w-full flex-col items-center">
          {data.cards && (
            <div className="mb-[20px] text-20/26 font-bold md:text-blue-dark xl:text-2xl">
              {diplayHeading}
            </div>
          )}

          {data?.images && data?.heading && (
            <div className="mb-[20px] text-20/26 font-bold md:text-blue-dark xl:text-2xl">
              {data?.heading}
            </div>
          )}
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            grabCursor={true}
            navigation={isBigScreen ? true : false}
            pagination={{ clickable: true }}
            modules={[Navigation, Pagination, Keyboard]}
            onSlideChange={(slide) => setSelectedIndex(slide.activeIndex)}
            className='w-full h-auto'
          >
            {diplayData?.map((card, index) => {
              return (
                <SwiperSlide
                  className="mx-auto flex flex-col items-center justify-start h-[80%] w-[860px]"
                  key={index}
                >
                  {card?.image?.url && (
                    <img
                      src={baseUrl + card?.image?.url}
                      alt={card?.image.altText}
                      className='md:max-w-[68%] max-w-full xl:max-w-[90%] pb-[32px]'
                    />
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div >
  );
};

export default ImageSliderModule;
