import React, { useEffect, useMemo, useState } from "react";
import { IContentModules, IECourse, IECourses } from "../../globalTypes";
import ECourseCard from "../../components/ECourseCard/ECourseCard";
import ReadMore from "../../components/svg/ReadMore";
import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { getNextPageData } from "../ContentPage/contentApi";
import { gridCardsOrderStyle, customModuleStyle } from "./CustomStyles";

interface IProps {
  data: IContentModules;
}

const CoursesModule = ({ data }: IProps) => {
  const [eCoursesData, setECoursesData] = useState<IECourse[]>([]);
  const [nextPage, setNextPage] = useState<number>(1);
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);

  useEffect(() => {
    if (data?.eCourses) {
      setECoursesData(data?.eCourses?.results);
      setHasNextPage(!!data?.eCourses?.hasNextPage || false);
    }

    if (data?.eCoursesGroup?.results) {
      setECoursesData(data?.eCoursesGroup?.results);
      setHasNextPage(!!data?.eCoursesGroup?.hasNextPage || false);
    }
  }, [data]);

  const nextPageLink = useMemo(() => {
    let newLink = "";
    if (data?.apiUrl) {
      if (data?.eCourses?.filter?.currentPageId) {
        newLink = `${nextPage}&currentPageId=${data?.eCourses?.filter?.currentPageId}`;
      }
      if (data?.eCourses?.filter?.moduleKey) {
        newLink = `${nextPage}&currentPageId=${data?.eCourses?.filter?.moduleKey}`;
      }

      if (data?.eCoursesGroup?.filter?.currentPageId) {
        newLink = `${nextPage}&currentPageId=${data?.eCoursesGroup?.filter?.currentPageId}`;
      }
      if (data?.eCoursesGroup?.filter?.moduleKey) {
        newLink = `${nextPage}&currentPageId=${data?.eCoursesGroup?.filter?.moduleKey}`;
      }
      newLink = data?.apiUrl.replace("page=2", `page=${nextPage}`);

      return newLink;
    }
  }, [data, nextPage]);

  useQuery<AxiosResponse<IECourses>, Error>(
    ["query-next-page", shouldFetch],
    async () => await getNextPageData(nextPageLink),
    {
      enabled: shouldFetch,
      onSuccess: (res) => {
        const newData = [...eCoursesData, ...res.data.results];
        setECoursesData(newData);
        setShouldFetch(false);
        setHasNextPage(res.data?.hasNextPage || false);
      },
    },
  );

  return (
    <div className={`${customModuleStyle(data?.withBackground)} px-10 xl:px-0`}>
      <div className=" lg:mx-auto xl:w-container">
        <div className="pb-[30px] font-roboto text-25 font-bold text-blue-dark xl:pb-[50px] xl:text-40 ">
          {data?.heading}
        </div>
        <div className={gridCardsOrderStyle}>
          {data.moduleName === "CoursesModule" &&
            eCoursesData?.map((item: IECourse, index: number) => (
              <ECourseCard
                key={index}
                data={item}
                moduleName={data?.moduleName}
              />
            ))}
          {data.moduleName === "CoursesGroupsModule" &&
            eCoursesData?.map((item: IECourse, index) => (
              <ECourseCard
                key={index}
                data={item}
                moduleName={data?.moduleName}
              />
            ))}
        </div>
        {hasNextPage && (
          <div key={data?.buttonText}>
            <button
              onClick={() => {
                setNextPage((current) => current + 1);
                setShouldFetch(true);
              }}
              className="read-more-btn font-base relative z-10 flex items-center justify-start pl-2 pt-[30px] font-medium text-blue lg:pt-10"
            >
              <span className="read-more-blue-hover">{data?.buttonText}</span>
              <ReadMore />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CoursesModule;
