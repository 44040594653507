import React, { useMemo } from "react";
import { IECourse } from "../../globalTypes";
import Clock from "../svg/Clock";
import getSymbolFromCurrency from "currency-symbol-map";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Briefcase from "../svg/Briefcase";
import { getLink } from "../functions/getLink";
import { eCourseCardStyle } from "../../pages/Modules/CustomStyles";

interface IProps {
  data: IECourse;
  moduleName: string;
  customStyle?: string;
}
const env = window._env_ ?? {}

const ECourseCard = ({ data, moduleName, customStyle }: IProps) => {
  const baseUrl = env.REACT_APP_IMG_BASE_URL;

  const linkURL = useMemo(() => {
    if (data?.absoluteUrl) {
      return getLink(data?.absoluteUrl || "");
    } else {
      return "/";
    }
  }, [data]);

  return (
    <div className={eCourseCardStyle(customStyle)}>
      <Link to={linkURL} className="flex h-full w-full flex-col justify-end">
        <div className="relative h-52 overflow-hidden">
          {data?.thumbnail?.url ? (
            <img
              src={baseUrl + data?.thumbnail.url}
              alt={data?.hero?.image?.altText}
              className="hover-img absolute left-0 top-0 h-full w-full object-cover transition-all duration-500 ease-out"
            />
          ) : (
            <div className="h-52 w-full animate-pulse overflow-hidden rounded-t-3xl bg-gray-200 p-3"></div>
          )}
        </div>

        <div
          data-tooltip-id={data?.id?.toString()}
          className="flex h-auto w-full items-end justify-between px-[20px] pb-[10px] pt-[20px] text-20/26 font-bold xl:text-2xl"
        >
          <span className="textEllipsis2Lines text-blue-dark">
            {data?.hero?.heading}
          </span>
          <span className="flex w-24 items-start justify-end">
            {data?.hero?.price}{" "}
            <span className="text-base xl:text-lg">
              {getSymbolFromCurrency(data?.hero?.priceCurrency || "")}
            </span>
          </span>
        </div>
        <ReactTooltip
          id={data?.id?.toString()}
          place="bottom"
          content={data?.hero?.heading}
        />

        <div className="mt-auto flex items-center justify-start px-[20px] pb-[20px] text-gray-secondary">
          {(moduleName === "CoursesModule" ||
            moduleName === "CoursesSliderModule") && (
            <>
              <Clock />
              <span className="ml-2">
                {data?.duration} {data?.durationUnit}
              </span>
            </>
          )}

          {(moduleName === "CoursesGroupsModule" ||
            moduleName === "CoursesGroupsSliderModule") && (
            <>
              <Briefcase />
              <span className="ml-2">{data.industry}</span>
            </>
          )}
        </div>
      </Link>
    </div>
  );
};

export default ECourseCard;
