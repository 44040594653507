import React, { useMemo } from "react";
import { IECourse } from "../../globalTypes";
import { Link } from "react-router-dom";
import { getLink } from "../functions/getLink";
import ReadMore from "../svg/ReadMore";

interface IProps {
  data: IECourse;
  linkLabel?: string;
}
const env = window._env_ ?? {}

const CustomerStoriesCard = ({ data, linkLabel }: IProps) => {
  const baseUrl = env.REACT_APP_IMG_BASE_URL;

  const linkURL = useMemo(() => {
    if (data?.absoluteUrl) {
      return getLink(data?.absoluteUrl || "");
    } else {
      return "/";
    }
  }, [data]);

  return (
    <div className="card-hover relative flex w-full flex-col overflow-hidden bg-white">
      <Link to={linkURL} className="flex h-full w-full flex-col">
        <div className="relative h-52 overflow-hidden">
          {data?.thumbnail?.url ? (
            <img
              src={baseUrl + data?.thumbnail.url}
              alt={data?.thumbnail.altText}
              className="hover-img absolute left-0 top-0 h-full w-full object-cover transition-all duration-500 ease-out"
            />
          ) : (
            <div className="w-full"></div>
          )}
        </div>

        {data?.customerLogo?.url && (
          <div>
            <img
              src={baseUrl + data?.customerLogo?.url}
              alt={data?.customerLogo?.altText}
              className="absolute inset-x-[10%] top-[20%] z-20 mx-auto h-[67px] w-[200px] sm:inset-x-[25%] md:inset-x-[30%] lg:inset-x-[20%]"
            />
          </div>
        )}
        <div className="flex h-auto w-full flex-col items-start justify-between text-xl">
          <span className="h-auto pb-[10px] pt-[20px] font-bold text-gray-border">
            {data.customerName}
          </span>
          <span className="text-sm text-gray">{data.summary}</span>
        </div>
        <div className="mt-auto">
          {data?.absoluteUrl ? (
            <div key={data?.absoluteUrl}>
              <div className="read-more-btn flex items-center justify-start pt-5 font-medium text-blue">
                <span className="read-more-blue-hover">{linkLabel}</span>
                <ReadMore />
              </div>
            </div>
          ) : (
            <div className="h-9"></div>
          )}
        </div>
      </Link>
    </div>
  );
};

export default CustomerStoriesCard;
