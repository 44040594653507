import React from "react";
import { inputFieldStyle } from "../../pages/Modules/CustomStyles";

interface IProps {
  id: string;
  name: string;
  value: string;
  isRequired?: boolean;
  onChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  hasError?: boolean;
  type?: string;
  placeholder: string;
  label: string;
  errMsg?: string;
}
const InputField = ({
  id,
  name,
  value,
  isRequired,
  onChange,
  hasError,
  type,
  placeholder,
  label,
  errMsg,
}: IProps) => {
  return (
    <div className="my-2 flex flex-col items-start">
      <div className="mb-1 flex items-start text-base font-medium text-blue-dark">
        {label}
        {isRequired && <div className="ml-1 text-base text-red">*</div>}
      </div>
      <input
        className={inputFieldStyle(hasError)}
        id={id}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      {hasError && errMsg ? (
        <div className="pl-4 text-red">{errMsg}</div>
      ) : (
        <div className="h-6"></div>
      )}
    </div>
  );
};

export default InputField;
