import { FeatureBlocks, IContentModules } from "../../globalTypes";
import RadialLeftBottom from "../../components/svg/RadialLeftBottom";
import Card from "../../components/Card/Card";
interface IProps {
  data: IContentModules;
}
const env = window._env_ ?? {}

const SpecialBannerFeaturesModule = ({ data }: IProps) => {
  const baseUrl = env.REACT_APP_IMG_BASE_URL;

  return (
    <div className="relative w-full bg-gray-light">
      <RadialLeftBottom />

      <div className="w-full px-[20px] py-[40px] lg:py-[50px] xl:mx-auto xl:w-container xl:px-0">
        <div className="xl:w-container">
          <h2 className="pb-[30px] text-25 font-bold text-blue-dark xl:pb-[50px] xl:text-40 ">
            {data?.heading}
          </h2>
        </div>
        <div className="grid-template gap-x-[30px] gap-y-[30px] xs:grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 xl:gap-y-[50px]">
          {data?.featureBlocks?.map((item: FeatureBlocks, index) => (
            <Card
              key={index}
              customClassNames="bg-white p-[30px] rounded-[40px]"
              customClassNameParagraph="lg:text-lg"
              image={baseUrl + item.icon.url}
              title={item.heading}
              paragraph={item.description}
              link={item.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export default SpecialBannerFeaturesModule;
