import React from "react";

const FullCircleRadial = () => {
  return (
    <svg
      className="absolute z-10 -mt-[620px] ml-[45%] w-full md:-mt-[57%] md:ml-[60%] md:w-[60%] lg:-mt-[40%] lg:ml-[75%] lg:w-[50%] xl:-mt-[45%] xl:ml-[71%] xl:w-[70%] 2xl:-mt-[35%] 2xl:ml-[74%] 2xl:w-[60%] 3xl:-mt-[25%] 3xl:ml-[69%]"
      width="1498"
      height="1498"
      viewBox="0 0 1498 1498"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="749.075"
        cy="748.925"
        r="543.5"
        transform="rotate(148 749.075 748.925)"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="2"
      />
      <path
        d="M1006.13 1191.69C762.78 1344.05 441.765 1269.28 290.545 1024.76C139.042 781.561 213.693 461.139 456.822 309.215C699.951 157.292 1020.6 232.662 1172.52 475.791C1324.44 718.918 1249.17 1039.66 1006.13 1191.69Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.926"
      />
      <path
        d="M974.915 1173.14C742.999 1318.65 437.363 1246.1 293.334 1012.34C150.321 780.227 221.355 475.47 452.833 330.826C684.311 186.182 990.003 259.444 1134.65 490.921C1279.29 722.399 1206.22 1028.28 974.915 1173.14Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.851"
      />
      <path
        d="M943.703 1154.59C723.218 1293.24 432.566 1223.18 296.122 999.914C161.199 779.142 229.016 489.796 448.843 352.433C668.67 215.069 959.408 286.224 1096.77 506.051C1234.13 725.879 1163.26 1016.9 943.703 1154.59Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.777"
      />
      <path
        d="M912.492 1136.04C703.439 1267.85 427.77 1200.26 298.911 987.49C172.076 778.064 236.679 504.126 444.855 374.043C653.032 243.959 928.816 313.004 1058.9 521.181C1188.98 729.359 1120.31 1005.52 912.492 1136.04Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.702"
      />
      <path
        d="M881.28 1117.49C683.659 1242.44 422.975 1177.34 301.698 975.065C182.945 776.992 244.339 518.455 440.865 395.652C637.391 272.849 898.222 339.785 1021.03 536.312C1143.83 732.837 1077.36 994.136 881.28 1117.49Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.628"
      />
      <path
        d="M850.068 1098.93C663.879 1217.04 418.18 1154.44 304.489 962.641C193.809 775.93 252.001 532.785 436.876 417.262C621.752 301.738 867.629 366.566 983.152 551.441C1098.68 736.317 1034.41 982.755 850.068 1098.93Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.553"
      />
      <path
        d="M818.858 1080.38C644.1 1191.64 413.389 1131.54 307.278 950.217C204.669 774.877 259.664 547.114 432.889 438.871C606.114 330.628 837.035 393.347 945.279 566.572C1053.52 739.798 991.462 971.376 818.858 1080.38Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.479"
      />
      <path
        d="M787.646 1061.83C624.09 1165.88 408.598 1108.65 310.066 937.791C215.517 773.834 267.325 561.441 428.9 460.478C590.473 359.515 806.443 420.126 907.406 581.701C1008.37 743.276 948.284 959.639 787.646 1061.83Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.404"
      />
      <path
        d="M756.434 1043.28C604.279 1140.44 403.805 1085.78 312.853 925.367C226.354 772.808 274.985 575.772 424.91 482.088C574.834 388.405 775.849 446.908 869.531 596.831C963.214 746.755 905.306 948.219 756.434 1043.28Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.33"
      />
      <path
        d="M725.223 1024.73C584.472 1115 399.017 1062.92 315.643 912.942C237.178 771.796 282.647 590.1 420.921 503.697C559.195 417.294 745.255 473.687 831.659 611.961C918.062 750.234 862.33 936.798 725.223 1024.73Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.255"
      />
      <path
        d="M694.011 1006.18C564.665 1089.56 394.228 1040.08 318.431 900.518C247.986 770.804 290.309 604.43 416.932 525.307C543.555 446.184 714.661 500.469 793.784 627.092C872.907 753.715 819.352 925.38 694.011 1006.18Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.181"
      />
      <path
        d="M662.798 987.627C544.857 1064.12 389.438 1017.27 321.218 888.093C258.769 769.843 297.969 618.759 412.942 546.916C527.915 475.073 684.067 527.249 755.91 642.222C827.753 757.195 776.379 913.966 662.798 987.627Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.106"
      />
      <path
        d="M631.588 969.075C525.05 1038.68 384.648 994.49 324.007 875.668C269.526 768.912 305.631 633.087 408.953 568.524C512.275 503.961 653.475 554.03 718.038 657.352C782.6 760.673 733.405 902.553 631.588 969.075Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.032"
      />
      <path
        d="M600.376 950.523C505.245 1013.25 379.854 971.752 326.798 863.243C280.239 768.025 313.292 647.416 404.964 590.133C496.636 532.85 622.882 580.809 680.165 672.481C737.448 764.152 690.434 891.146 600.376 950.523Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="0.957"
      />
      <path
        d="M569.163 931.973C485.439 987.817 375.048 949.074 329.584 850.82C290.892 767.199 320.953 661.746 400.974 611.743C480.995 561.74 592.288 607.591 642.29 687.612C692.293 767.633 647.464 879.746 569.163 931.973Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="0.883"
      />
      <path
        d="M537.952 913.421C465.638 962.393 370.22 926.474 332.372 838.394C301.46 766.449 328.614 676.074 396.985 633.352C465.355 590.629 561.693 634.371 604.416 702.742C647.139 771.113 604.5 868.353 537.952 913.421Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="0.809"
      />
      <path
        d="M506.741 894.869C445.84 936.981 365.342 903.992 335.163 825.969C311.892 765.81 336.276 690.403 392.996 654.96C449.716 619.517 531.1 661.15 566.544 717.871C601.987 774.591 561.544 856.973 506.741 894.869Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="0.734"
      />
      <path
        d="M475.528 876.318C426.049 911.588 360.343 881.687 337.95 813.545C322.104 765.329 343.937 704.733 389.006 676.571C434.076 648.408 500.507 687.932 528.669 733.001C556.832 778.071 518.597 845.618 475.528 876.318Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="0.66"
      />
      <path
        d="M444.318 857.767C406.278 886.23 355.031 859.642 340.739 801.121C331.932 765.058 351.599 719.061 385.018 698.179C418.438 677.295 469.915 714.712 490.797 748.132C511.68 781.551 475.676 834.302 444.318 857.767Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="0.585"
      />
      <path
        d="M413.106 839.215C386.564 860.962 348.7 837.937 343.527 788.695C341.041 765.017 359.26 733.39 381.03 719.787C402.798 706.184 439.32 741.493 452.923 763.262C466.525 785.03 432.81 823.07 413.106 839.215Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="0.511"
      />
      <path
        d="M381.895 820.664C367.179 836.034 337.836 815.518 346.318 776.271C348.838 764.61 366.922 747.72 377.042 741.396C387.16 735.074 408.728 768.273 415.05 778.391C421.373 788.51 390.146 812.046 381.895 820.664Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="0.436"
      />
    </svg>
  );
};

export default FullCircleRadial;
