import "../../assets/scss/homepage.scss";
import { useMemo } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Keyboard } from 'swiper/modules';

interface TestimonialsCardProps {
  authorDesignation: string;
  authorName: string;
  content: string;
  customerName: string;
}

const TestimonialsCard = ({
  authorDesignation,
  authorName,
  content,
  customerName,
}: TestimonialsCardProps) => {
  const statement = useMemo(() => {
    if (content) {
      if (content.includes("\n")) {
        const newContentArr = content.split("\n");
        const contentArr = newContentArr.filter((item) => item.trim());
        return contentArr;
      } else {
        return [content];
      }
    }
  }, [content]);

  return (
    <div className="relative flex flex-col justify-between bg-white px-[30px] py-[47px] text-gray">
      <div className="absolute -ml-[10px] -mt-[65px]">
        <svg
          width="70"
          height="35"
          viewBox="0 0 70 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0L34.9999 35H0V0Z" fill="#DDE0FF" />
          <path d="M35 0L69.9999 35H35V0Z" fill="#DDE0FF" />
        </svg>
      </div>
      {statement && statement.length === 1 && (
        <div className="mb-[50px] text-lg">{statement[0]}</div>
      )}
      {statement && statement.length > 1 && (
        <div className="flex h-full items-start justify-start lg:h-[73%]">
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            grabCursor={true}
            pagination={{ clickable: true }}
            modules={[Pagination, Keyboard]}
            className='lg:w-[90%] w-full h-full'
          >
            {statement.map((s, index) => (
              <SwiperSlide
                className="mx-auto flex flex-col items-center justify-start "
                key={index}
              >
                <div className='pb-[20px]'>
                  {s}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      <div className="text-base">
        <div className="font-bold text-blue-dark">{customerName}</div>
        <div>
          {authorName}, {authorDesignation}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsCard;
