import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import Page404 from "../pages/Page404/Page404";
import { RouteLink } from "../globalTypes";
import ContentWrapper from "../pages/ContentPage/ContentWrapper";
import { getLink } from "../components/functions/getLink";
interface IProp {
  routeArr: RouteLink[] | [];
}
const AppRoutes = ({ routeArr }: IProp) => {
  return (
    <Routes>
      <Route element={<Layout />}>
        {routeArr?.map((page) => {
          const route = getLink(page?.redirectUrl ?? page?.url);
          if (page.pageTemplate === "Homepage") {
            return (
              <Route key={page.id}>
                <Route
                  key={page.id}
                  index
                  path="/"
                  element={<Navigate to={page.url} />}
                />
                <Route
                  key={page.id}
                  path={route}
                  element={
                    <ContentWrapper
                      url={route}
                      pageTemplate={page.pageTemplate}
                    />
                  }
                />
              </Route>
            );
          } else {
            const route = getLink(page?.redirectUrl ?? page?.url);
            return (
              <Route key={page.id}>
                {page?.pageTemplate === "Homepage" ||
                  (page.redirectUrl && (
                    <Route
                      key={page.id}
                      index
                      path={page?.pageTemplate === "Homepage" ? "/" : page.url}
                      element={
                        <Navigate
                          to={
                            page?.pageTemplate === "Homepage" ? page.url : route
                          }
                        />
                      }
                    />
                  ))}
                <Route
                  key={page.id}
                  path={route}
                  element={
                    <ContentWrapper
                      url={route}
                      pageTemplate={page.pageTemplate}
                    />
                  }
                />
              </Route>
            );
          }
        })}
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default AppRoutes;
