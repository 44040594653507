import React, { useRef } from "react";
import { IECourse } from "../../globalTypes";
import ReactPlayer from "react-player";
import { useOnClickOutside } from "usehooks-ts";

interface IProps {
  handleClose: () => void;
  data: IECourse | null;
}
const Dialog = ({ data, handleClose }: IProps) => {
  const ref = useRef(null);
  useOnClickOutside(ref, handleClose);
  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
        <div className="relative z-50 mx-auto my-6 w-auto">
          <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
            <div
              ref={ref}
              className="relative h-full w-full flex-auto p-0 lg:min-w-[600px]"
            >
              <ReactPlayer
                controls
                url={data?.videoUrl}
                width="70vw"
                height="70vh"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  );
};

export default Dialog;
