import axios from 'axios';

const env = window._env_ ?? {}

const instance = axios.create({
  baseURL: env.REACT_APP_DEV_BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'multipart/form-data',
    Accept: 'multipart/form-data',
    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
  },
});

export default instance;
