import React, { useMemo } from "react";
import { IContentModules, IECourse } from "../../globalTypes";
import ECourseCard from "../../components/ECourseCard/ECourseCard";
import useMediaQuery from "../../components/hooks/useMediaQuery";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { customModuleStyle } from "./CustomStyles";

interface IProps {
  data: IContentModules;
}
const CoursesSliderModule = ({ data }: IProps) => {
  const isSmallScreen = useMediaQuery("(max-width: 640px)");
  const isBigScreen = useMediaQuery("(min-width: 1024px)");

  const displayData = useMemo(() => {
    let newData: IECourse[] = [];
    if (data?.courses) {
      newData = data?.courses;
    }

    if (data?.coursesGroups) {
      newData = data?.coursesGroups;
    }

    if (isBigScreen) {
      const newArrLength = newData.length / 3;
      // eslint-disable-next-line no-unsafe-optional-chaining
      const oldDataArr = newData ? [...newData] : [];
      const newDataArr = [];
      for (let i = 0; i < newArrLength; i++) {
        const group = oldDataArr.slice(0, 3);
        newDataArr.push(group);
        oldDataArr.splice(0, 3);
      }
      return newDataArr;
    }
    if (isSmallScreen) {
      return newData?.map((c) => [{ ...c }]);
    }

    if (!isSmallScreen && !isBigScreen) {
      const newArrLength = newData.length / 2;
      // eslint-disable-next-line no-unsafe-optional-chaining
      const oldDataArr = newData ? [...newData] : [];
      const newDataArr = [];
      for (let i = 0; i < newArrLength; i++) {
        const group = oldDataArr.slice(0, 2);
        newDataArr.push(group);
        oldDataArr.splice(0, 2);
      }
      return newDataArr;
    }
  }, [isBigScreen, isSmallScreen]);

  return (
    <div className={`${customModuleStyle(data?.withBackground)} px-9 xl:px-0`}>
      <div className="flex flex-col lg:mx-auto xl:w-container">
        <div className="xl:text-40 text-25 mb-[30px] font-roboto font-bold md:text-blue-dark xl:mb-[50px] ">
          {data?.heading}
        </div>
        <div>
          {displayData && displayData?.length === 1 && (
            <>
              {displayData?.map((subArr, index) => {
                return (
                  <div
                    key={index}
                    className="flex h-[380px] justify-between"
                  >
                    {subArr.map((card, index) => {
                      return (
                        <div
                          key={index}
                          className="h-auto w-full"
                        >
                          <ECourseCard
                            data={card}
                            moduleName={data?.moduleName}
                            customStyle="md:w-[90%] md:max-w-[430px] my-0"
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </>
          )}
          {displayData && displayData?.length > 1 && (
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              grabCursor={true}
              pagination={{ clickable: true }}
              modules={[Navigation, Pagination]}
              className='lg:w-[90%] w-full'
            >
              {displayData?.map((subArr, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    className="flex h-[380px] justify-between"
                  >
                    {subArr.map((card, index) => {
                      return (
                        <div
                          key={index}
                          className="h-full w-full"
                        >
                          <ECourseCard
                            data={card}
                            moduleName={data?.moduleName}
                            customStyle="md:w-[90%] md:max-w-[346px]"
                          />
                        </div>
                      );
                    })}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoursesSliderModule;
