import React from "react";
import { IContentModules } from "../../globalTypes";

interface IProps {
  data: IContentModules;
}
const AnchorModule = ({ data }: IProps) => {
  return <a id={data?.id}></a>;
};

export default AnchorModule;
