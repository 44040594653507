import React from "react";
import { IContentModules, IImage } from "../../globalTypes";
import {
  courseDescriptionModuleInfoStyle,
  courseDescriptionModulelabelStyle,
} from "./CustomStyles";

interface IProps {
  data: IContentModules;
  pricingModelLabel?: string;
  pricingModelIcon?: IImage;
  languagesLabel?: string;
  languagesIcon?: IImage;
  interactivityLevelLabel?: string;
  interactivityLevelIcon?: IImage;
  durationLabel?: string;
  durationIcon?: IImage;
  industryLabel?: string;
  industryIcon?: IImage;
}

const CourseDescriptionModule = ({
  data,
  pricingModelLabel,
  pricingModelIcon,
  languagesLabel,
  languagesIcon,
  interactivityLevelLabel,
  interactivityLevelIcon,
  durationLabel,
  durationIcon,
  industryLabel,
  industryIcon,
}: IProps) => {

  const env = window._env_ ?? {}

  const baseUrl = env.REACT_APP_IMG_BASE_URL;

  return (
    <div className="h-full w-full bg-blue-light py-[40px] lg:py-[50px]">
      <div className="flex h-full grow flex-col items-start justify-between px-10 lg:mx-auto lg:flex-row xl:w-container xl:p-0">
        <div className="w-full pb-[30px] lg:w-[660px] lg:pb-0 lg:pr-[30px]">
          <div
            className="dangerouslySetInnerHTML w-full"
            dangerouslySetInnerHTML={{ __html: data?.description || "" }}
          ></div>
        </div>
        <div className="flex h-full w-full grow flex-col flex-wrap  items-center justify-center rounded-3xl border border-gray-light px-[30px] lg:mt-[50px] lg:w-[480px] lg:flex-row lg:border-none lg:px-0">
          {data?.duration && data?.durationUnit && (
            <div className={courseDescriptionModuleInfoStyle}>
              {durationIcon?.url ? (
                <div>
                  <img
                    src={baseUrl + durationIcon?.url}
                    alt={durationIcon.altText}
                    className="h-[30px] w-[30px] lg:h-12 lg:w-12"
                  />
                </div>
              ) : (
                <div className="h-[30px] w-[30px] lg:h-12 lg:w-12"></div>
              )}
              <div className="flex flex-col justify-between">
                {durationLabel ? (
                  <div className={courseDescriptionModulelabelStyle}>
                    {durationLabel}
                  </div>
                ) : (
                  <div className="h-11"></div>
                )}
                <div className="ml-4 text-gray">
                  {data?.duration} {data?.durationUnit}
                </div>
              </div>
            </div>
          )}
          {data?.industry && (
            <div className={courseDescriptionModuleInfoStyle}>
              {industryIcon?.url ? (
                <div>
                  <img
                    src={baseUrl + industryIcon?.url}
                    alt={industryIcon.altText}
                    className="h-[30px] w-[30px] lg:h-12 lg:w-12"
                  />
                </div>
              ) : (
                <div className="h-[30px] w-[30px] lg:h-12 lg:w-12"></div>
              )}
              <div className="flex flex-col justify-between">
                {industryLabel ? (
                  <div className={courseDescriptionModulelabelStyle}>
                    {industryLabel}
                  </div>
                ) : (
                  <div className="h-11"></div>
                )}
                <div className="ml-4 text-gray">{data?.industry}</div>
              </div>
            </div>
          )}
          <div className={courseDescriptionModuleInfoStyle}>
            {interactivityLevelIcon?.url ? (
              <div>
                <img
                  src={baseUrl + interactivityLevelIcon?.url}
                  alt={interactivityLevelIcon.altText}
                  className="h-[30px] w-[30px] lg:h-12 lg:w-12"
                />
              </div>
            ) : (
              <div className="h-[30px] w-[30px] lg:h-12 lg:w-12"></div>
            )}
            <div className="flex flex-col justify-between">
              {interactivityLevelLabel ? (
                <div className={courseDescriptionModulelabelStyle}>
                  {interactivityLevelLabel}
                </div>
              ) : (
                <div className="h-11"></div>
              )}
              <div className="ml-4 text-gray">{data?.interactivityLevel}</div>
            </div>
          </div>
          <div className={courseDescriptionModuleInfoStyle}>
            {languagesIcon?.url ? (
              <div>
                <img
                  src={baseUrl + languagesIcon?.url}
                  alt={languagesIcon.altText}
                  className="h-[30px] w-[30px] lg:h-12 lg:w-12"
                />
              </div>
            ) : (
              <div className="h-[30px] w-[30px] lg:h-12 lg:w-12"></div>
            )}
            <div className="flex flex-col justify-between">
              {languagesLabel ? (
                <div className={courseDescriptionModulelabelStyle}>
                  {languagesLabel}
                </div>
              ) : (
                <div className="h-11"></div>
              )}
              <div className="ml-4 text-gray">
                {data?.languages?.join(", ")}
              </div>
            </div>
          </div>
          <div className={courseDescriptionModuleInfoStyle}>
            {pricingModelIcon?.url ? (
              <div>
                <img
                  src={baseUrl + pricingModelIcon?.url}
                  alt={pricingModelIcon.altText}
                  className="h-[30px] w-[30px] lg:h-12 lg:w-12"
                />
              </div>
            ) : (
              <div className="h-[30px] w-[30px] lg:h-12 lg:w-12"></div>
            )}
            <div className="flex flex-col justify-between">
              {pricingModelLabel ? (
                <div className={courseDescriptionModulelabelStyle}>
                  {pricingModelLabel}
                </div>
              ) : (
                <div className="h-11"></div>
              )}
              <div className="ml-4 text-gray">{data?.pricingModel}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDescriptionModule;
