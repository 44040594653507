import React from "react";
import { ICard, IContentModules } from "../../globalTypes";
import CardWithNumber from "../../components/CardWithNumber/CardWithNumber";
import { customModuleStyle } from "./CustomStyles";

interface IProps {
  data: IContentModules;
}

const env = window._env_ ?? {}
 
const CardsWithNumbersModule = ({ data }: IProps) => {
  const baseUrl = env.REACT_APP_IMG_BASE_URL;

  return (
    <div className={`${customModuleStyle(data?.withBackground)} px-10 xl:px-0`}>
      <div className="lg:mx-auto xl:w-container">
        <h2 className="pb-[30px] text-25 font-bold text-blue-dark xl:pb-[50px] xl:text-40 ">
          {data?.heading}
        </h2>
        <p className="font-roboto text-xl text-gray-dark">{data?.subHeading}</p>
        <div className="flex flex-col flex-wrap justify-between lg:flex-row">
          {data?.cards?.map((item: ICard, index) => (
            <CardWithNumber item={item} index={index} key={index} />
          ))}
        </div>
      </div>
      {/* {data?.image?.url && (
        <div className="lg:-mt-22 hidden sm:block lg:mx-auto xl:w-container xl:px-0">
          <img src={baseUrl + data?.image?.url} />
        </div>
      )}
      {data?.imageMobile?.url && (
        <div className="flex w-full justify-center px-0 sm:hidden">
          <img src={baseUrl + data?.imageMobile?.url} />
        </div>
      )} */}
    </div>
  );
};

export default CardsWithNumbersModule;
