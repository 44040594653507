import React from "react";
import { IContentModules } from "../../globalTypes";
import { customModuleStyle } from "./CustomStyles";

interface IProps {
  data: IContentModules;
}
const ParagraphModule = ({ data }: IProps) => {
  return (
    <div
      className={`${customModuleStyle(data?.withBackground)} px-[30px] xl:px-0`}
    >
      <div className="w-full lg:mx-auto xl:w-[770px]">
        <div className="flex items-center justify-center">
          <div className="dangerouslySetInnerHTML w-full text-2xl text-gray-secondary">
            <div
              dangerouslySetInnerHTML={
                { __html: data?.text || "" } as { __html: string }
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParagraphModule;
