import React, { useMemo, useState } from "react";
import SelectField from "../../../components/SelectField/SelectField";
import InputField from "../../../components/InputField/InputField";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import TextArea from "../../../components/TextArea/TextArea";
import {
  IApiResponse,
  ICard,
  IContentModules,
  IPlanFormValues,
} from "../../../globalTypes";
import { submitForm } from "../../ContentPage/contentApi";
import ReCAPTCHAComp from "../../../components/ReCAPTCHA/ReCAPTCHAComp";
import { pricingModuleFormDialog } from "../CustomStyles";
import ResponceFormData from "../../../components/ResponceForm/ResponceFormData";
import AgreeToTermsCheckbox from "../../../components/AgreeToTermsCheckbox/AgreeToTermsCheckbox";

interface IProps {
  data: IContentModules | null;
  planSelected?: ICard | null;
}

const validationSchema = Yup.object({
  email: Yup.string().email("Not a valid email").required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  planId: Yup.string().required("Required"),
  description: Yup.string(),
  isVerified: Yup.boolean().oneOf([true], "Required"),
  isAgreedToTerms: Yup.boolean().oneOf([true], "Required"),
});

const PricingModuleForm = ({ data, planSelected }: IProps) => {
  const [respData, setRespData] = useState<IApiResponse | null>(null);

  const planOptions = useMemo(() => {
    const options = data?.cards.map((card) => {
      return { label: card?.planNameDropdown, value: card?.id?.toString() };
    });
    return options ? options : [];
  }, [data]);

  const initialValues = useMemo(() => {
    if (planSelected?.id) {
      return {
        firstName: "",
        lastName: "",
        email: "",
        planId: planSelected.id.toString(),
        description: "",
        isVerified: false,
        isAgreedToTerms: false,
      };
    } else {
      return {
        firstName: "",
        lastName: "",
        email: "",
        planId: "",
        description: "",
        isVerified: false,
        isAgreedToTerms: false,
      };
    }
  }, [planSelected]);

  const handleSubmit = async (
    values: IPlanFormValues,
    actions: FormikHelpers<IPlanFormValues>,
  ) => {
    actions.setSubmitting(true);

    const { firstName, lastName, email, planId, description } = values;

    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("planId", planId);
    formData.append("description", description);

    try {
      const status = await submitForm(formData, data?.formApiUrl);
      if (status?.data) {
        setRespData(status.data);
        actions.resetForm();
        actions.setSubmitting(false);
      }
    } catch (error) {
      actions.resetForm();
      actions.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={pricingModuleFormDialog(!!respData)}>
        {!respData && (
          <>
            <h2 className="pb-5 font-roboto text-20/26 font-bold text-blue-dark xl:text-3xl">
              {data?.formHeading}
            </h2>
            <>
              <InputField
                id="firstName"
                name="firstName"
                value={formik.values.firstName}
                isRequired={true}
                placeholder={data?.formFirstNamePlaceholder || ""}
                label={data?.formFirstNameLabel || ""}
                type="text"
                hasError={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                errMsg={
                  formik.touched.firstName && formik.errors.firstName
                    ? formik.errors.firstName
                    : ""
                }
                onChange={(e) => formik.handleChange(e)}
              />
              <InputField
                id="lastName"
                name="lastName"
                value={formik.values.lastName}
                isRequired={true}
                placeholder={data?.formLastNamePlaceholder || ""}
                label={data?.formLastNameLabel || ""}
                type="text"
                hasError={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                errMsg={
                  formik.touched.lastName && formik.errors.lastName
                    ? formik.errors.lastName
                    : ""
                }
                onChange={(e) => formik.handleChange(e)}
              />
              <InputField
                id="email"
                name="email"
                value={formik.values.email}
                isRequired={true}
                placeholder={data?.formEmailPlaceholder || ""}
                label={data?.formEmailLabel || ""}
                type="email"
                hasError={formik.touched.email && Boolean(formik.errors.email)}
                errMsg={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : ""
                }
                onChange={(e) => formik.handleChange(e)}
              />
              <SelectField
                id="planId"
                name="planId"
                value={formik.values.planId}
                onChange={(e) => formik.setFieldValue("planId", e)}
                options={planOptions}
                placeholder={planSelected?.planNameDropdown || ""}
                label={data?.formPlanLabel || ""}
                hasError={
                  formik.touched.planId && Boolean(formik.errors.planId)
                }
                errMsg={
                  formik.touched.planId && formik.errors.planId
                    ? formik.errors.planId
                    : ""
                }
              />
              <TextArea
                id="description"
                name="description"
                value={formik.values.description}
                placeholder={data?.formDescriptionPlaceholder || ""}
                label={data?.formDescriptionLabel || ""}
                onChange={(e) => formik.handleChange(e)}
              />

              <AgreeToTermsCheckbox
                isChecked={formik.values.isAgreedToTerms}
                onChange={(e) =>
                  formik.setFieldValue("isAgreedToTerms", e.target.checked)
                }
                label={data?.agreeToTermsText || ""}
                hasError={
                  formik.touched.isAgreedToTerms &&
                  Boolean(formik.errors.isAgreedToTerms)
                }
                errMsg={
                  formik.touched.isAgreedToTerms &&
                    formik.errors.isAgreedToTerms
                    ? formik.errors.isAgreedToTerms
                    : ""
                }
              />

              <ReCAPTCHAComp
                label={data?.reCaptchaLabel || ""}
                onChange={() => formik.setFieldValue("isVerified", true)}
                hasError={
                  formik.touched.isVerified && Boolean(formik.errors.isVerified)
                }
                errMsg={
                  formik.touched.isVerified && formik.errors.isVerified
                    ? formik.errors.isVerified
                    : ""
                }
              />
            </>
          </>
        )}
        {respData && (
          <ResponceFormData respData={respData} setRespData={setRespData} />
        )}
      </div>

      {!respData && (
        <div className="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md px-4 pb-4">
          <button
            type="submit"
            className="flex w-full items-center justify-center whitespace-nowrap rounded-sm border border-blue bg-blue p-[15px] text-white hover:border-blue-hover hover:bg-blue-hover"
          >
            {data?.formButtonText}
          </button>
        </div>
      )}
    </form>
  );
};

export default PricingModuleForm;
