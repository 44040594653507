import React from "react";
import { ICard } from "../../globalTypes";
import getSymbolFromCurrency from "currency-symbol-map";
import Check from "../svg/Check";
import LinkComp from "../LinkComp/LinkComp";
import {
  pricingInteractivityCardSectionStyle,
  pricingInteractivityCardStyle,
} from "../../pages/Modules/CustomStyles";

interface Props {
  data: ICard;
}
const PricingInteractivityCard = ({ data }: Props) => {
  return (
    <div className="my-4 flex w-full flex-col lg:w-[30%]">
      {data?.label && data?.label?.length > 0 ? (
        <div className="rounded-t-[50px] bg-blue px-8 py-4 text-xl font-semibold text-white">
          {data?.label}
        </div>
      ) : (
        <div className="h-0 lg:h-[60px]"></div>
      )}
      <div className={pricingInteractivityCardStyle(data?.label)}>
        <div className={pricingInteractivityCardSectionStyle}>
          <h2 className="pb-1 text-2xl font-bold text-blue xl:text-50">
            {data?.planName}
          </h2>
          <p className="pb-4 font-roboto text-xl font-bold text-blue-dark xl:text-2xl">
            {data?.subHeading}
          </p>
          {data?.whoDescriptionLabel &&
          data?.whoDescriptionLabel?.length > 0 ? (
            <div className="my-1 text-lg font-semibold text-gray-border">
              {data?.whoDescriptionLabel}
            </div>
          ) : (
            <div className="h-9"></div>
          )}
          <p className="h-auto font-roboto text-lg text-gray-secondary lg:h-20">
            {data?.whoDescription}
          </p>
        </div>

        <div className={pricingInteractivityCardSectionStyle}>
          {data?.contentsTextLabel && data?.contentsTextLabel?.length > 0 ? (
            <div className="my-1 text-lg font-semibold text-gray-border">
              {data?.contentsTextLabel}
            </div>
          ) : (
            <div className="h-9"></div>
          )}
          <div className="pb-4 text-3xl font-bold text-blue-dark">
            {data?.contentsText}
          </div>
          {data?.timeToDevelopTextLabel &&
          data?.timeToDevelopTextLabel?.length > 0 ? (
            <div className="my-1 text-lg font-semibold text-gray-border">
              {data?.timeToDevelopTextLabel}
            </div>
          ) : (
            <div className="h-9"></div>
          )}
          <div className="pb-4 text-3xl font-bold text-blue-dark">
            {data?.timeToDevelopText}
          </div>
          {data?.ratePerHourLabel && data?.ratePerHourLabel?.length > 0 ? (
            <div className="my-1 text-lg font-semibold text-gray-border">
              {data?.ratePerHourLabel}
            </div>
          ) : (
            <div className="h-9"></div>
          )}
          <div className="text-2xl font-bold text-blue-dark xl:text-40 ">
            {data?.ratePerHour}{" "}
            {getSymbolFromCurrency(data?.rateCurrency || "")}
          </div>
        </div>

        <div className={pricingInteractivityCardSectionStyle}>
          {data?.whatDescriptionLabel &&
          data?.whatDescriptionLabel?.length > 0 ? (
            <div className="my-1 text-lg font-semibold text-gray-border">
              {data?.whatDescriptionLabel}
            </div>
          ) : (
            <div className="h-9"></div>
          )}
          <p className="h-auto pb-4 font-roboto text-lg text-gray-secondary lg:h-36">
            {data?.whatDescription}
          </p>
          {data?.featureListLabel && data?.featureListLabel?.length > 0 ? (
            <div className="my-1 text-lg font-semibold text-gray-border">
              {data?.featureListLabel}
            </div>
          ) : (
            <div className="h-9"></div>
          )}
          <div>
            {data?.featureList?.map((item, index) => (
              <div key={index}>
                {item.isChecked ? (
                  <div className="flex items-center justify-start p-[6px]">
                    <div className=" pr-4">
                      <Check />
                    </div>
                    <div className="text-gray">{item?.text}</div>
                  </div>
                ) : (
                  <div className="flex items-center justify-start p-[6px]">
                    <div className="h-6 w-[13px]  pr-8"></div>
                    <div className="text-gray-border">{item?.text}</div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="py-[30px]">
          {data?.whenDescriptionLabel &&
          data?.whenDescriptionLabel?.length > 0 ? (
            <div className="my-1 text-lg font-semibold text-gray-border">
              {data?.whenDescriptionLabel}
            </div>
          ) : (
            <div className="h-9"></div>
          )}
          <p className="h-auto font-roboto text-lg text-gray-secondary lg:h-40">
            {data?.whenDescription}
          </p>
          {data?.link ? (
            <div key={data?.link.label} className="w-[140px]">
              <LinkComp
                linkUrl={data?.link?.url}
                target={data?.link?.target}
                label={data.link.label}
                shouldShowArrow={true}
                customStyle="read-more-btn flex items-center justify-start text-base font-medium text-blue lg:pt-0 pt-[30px]"
                customLabelStyle="read-more-blue-hover"
                isExternal={data?.link?.isExternal}
              />
            </div>
          ) : (
            <div className="h-9"></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PricingInteractivityCard;
