import React from "react";
import { IECourse } from "../../globalTypes";
import Clock from "../svg/Clock";
import { videoCardThumbnail } from "../../pages/Modules/CustomStyles";

interface IProps {
  data: IECourse;
}
const env = window._env_ ?? {}

const VideoCard = ({ data }: IProps) => {
  const baseUrl = env.REACT_APP_IMG_BASE_URL;

  return (
    <div className="flex h-full w-full flex-col bg-white">
      <div className="w-full">
        {data?.thumbnail?.url ? (
          <img
            src={baseUrl + data?.thumbnail?.url}
            alt={data?.thumbnail?.altText}
            className={videoCardThumbnail(!!data?.thumbnail?.url)}
          />
        ) : (
          <div className={videoCardThumbnail(!!data?.thumbnail?.url)}></div>
        )}
      </div>

      <div className="flex flex-col pt-5">
        <div className="w-full pb-2 text-xl font-bold text-gray-border">
          {data?.subHeading}
        </div>
        <div className="w-full pb-2 text-2xl font-bold text-blue-dark">
          {data?.heading}
        </div>
      </div>

      <div className="mt-auto flex h-6 items-center justify-start text-gray-secondary">
        <Clock />
        <span className="ml-2">
          {data?.duration} {data?.durationUnit}
        </span>
      </div>
    </div>
  );
};

export default VideoCard;
