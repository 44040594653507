import React from "react";
import ReCAPTCHA from "react-google-recaptcha-enterprise";

interface IProps {
  onChange: () => void;
  hasError?: boolean;
  type?: string;
  label: string;
  errMsg?: string;
}
const env = window._env_ ?? {}

const ReCAPTCHAComp = ({ hasError, onChange, label, errMsg }: IProps) => {
  const recaptchaKey = env.REACT_APP_SITE_KEY;

  return (
    <div className="mb-[26px] mt-2 flex flex-col items-start justify-start">
      <div className="mb-1 flex items-start text-base font-medium  text-blue-dark">
        {label}
        <div className="ml-1 text-base text-red">*</div>
      </div>
      <div>
        <ReCAPTCHA
          sitekey={recaptchaKey || ""}
          onChange={() => onChange()}
          size="normal"
          className="-ml-[15px] scale-x-[90%] sm:ml-0 sm:scale-100"
        />
      </div>
      {hasError && errMsg ? (
        <div className="pl-4 text-red">{errMsg}</div>
      ) : (
        <div className="h-6"></div>
      )}
    </div>
  );
};

export default ReCAPTCHAComp;
