import React from "react";
import { IContentModules } from "../../globalTypes";
import LinkComp from "../../components/LinkComp/LinkComp";
import {
  darkButtonStyle,
  lightButtonStyle,
  rightSectionStyle,
} from "./CustomStyles";

interface IProps {
  data: IContentModules;
}

const CallToActionModule = ({ data }: IProps) => {
  return (
    <div className="relative z-20 -mb-20 h-auto w-full pt-[40px] xl:pt-[80px]">
      <div className="bg-image relative z-20 mx-9 flex flex-col items-end justify-between rounded-3xl bg-blue p-[30px] lg:flex-row lg:items-center xl:mx-auto xl:w-[1270px] xl:p-12">
        <div className={rightSectionStyle(data?.links)}>
          <h2 className="pb-5 text-40 font-bold xl:pb-7">{data?.heading}</h2>
          <p className="text-30">{data?.description}</p>
        </div>

        {data?.links && (
          <div className="flex w-full flex-col items-end justify-end pt-5 lg:w-auto lg:flex-row lg:pt-0 xl:items-center">
            {data?.links?.map((l, index) => (
              <div
                key={index}
                className="mr-[20px] w-full self-end lg:w-auto lg:min-w-[180px]"
              >
                <LinkComp
                  linkUrl={l?.link?.url}
                  target={l?.link?.target}
                  label={l.link.label}
                  customStyle={
                    l?.color === "dark"
                      ? `${darkButtonStyle} ml-4 mt-4 w-full border-white px-[30px] py-[15px] hover:border-white`
                      : l?.color === "light"
                      ? `${lightButtonStyle} ml-4 mt-4 w-full border-white px-[30px] py-[15px] hover:border-white`
                      : `${darkButtonStyle} ml-4 mt-4 w-full border-white px-[30px] py-[15px] hover:border-white`
                  }
                  isExternal={l?.link?.isExternal}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CallToActionModule;
