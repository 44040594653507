import React, { Dispatch, SetStateAction, useMemo, useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";
import PricingModuleForm from "./PricingModuleForm";
import { IContentModules } from "../../../globalTypes";
import GetStartedContext from "./GetStartedContext";
import { pricingModuleDialogStyle } from "../CustomStyles";

interface IProps {
  data: IContentModules | null;
  handleClose: () => void;
  planOpen: number | null;
  formOpen: boolean;
  setFormOpen: Dispatch<SetStateAction<boolean>>;
  getStartedOpen: boolean;
  setGetStartedOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PricingDialog = ({
  data,
  handleClose,
  planOpen,
  formOpen,
  setFormOpen,
  getStartedOpen,
  setGetStartedOpen,
}: IProps) => {
  const ref = useRef(null);
  useOnClickOutside(ref, handleClose);

  const selectedPlan = useMemo(() => {
    if (planOpen) {
      const plan = data?.cards.find((card) => card.id === planOpen);
      return plan ? plan : null;
    }
  }, [planOpen]);

  return (
    <>
      <div
        ref={ref}
        className={pricingModuleDialogStyle(formOpen, getStartedOpen)}
      >
        <div className="pointer-events-auto relative z-40 mt-20 flex w-full flex-col overflow-hidden rounded-[20px] border bg-white bg-clip-padding text-current shadow-lg outline-none xl:mt-28">
          <div className="flex items-center justify-end rounded-t-[20px] p-2.5">
            <button
              type="button"
              className="box-content rounded-[50%] border-none bg-gray-closeBtn p-2 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
              aria-label="Close"
              onClick={handleClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-4 w-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          {formOpen && (
            <PricingModuleForm data={data} planSelected={selectedPlan} />
          )}
          {getStartedOpen && (
            <GetStartedContext
              data={data}
              planSelected={selectedPlan}
              setFormOpen={setFormOpen}
              setGetStartedOpen={setGetStartedOpen}
            />
          )}
        </div>
      </div>
      <div className="fixed inset-0 z-30 bg-black opacity-25"></div>
    </>
  );
};

export default PricingDialog;
