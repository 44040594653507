import React, { useMemo, useState } from "react";
import { IContentModules } from "../../../globalTypes";
import PricingModuleCard from "../../../components/PricingModuleCard/PricingModuleCard";
import PricingDialog from "./PricingDialog";
import { customModuleStyle } from "../CustomStyles";

interface IProps {
  data: IContentModules;
}

const PricingModule = ({ data }: IProps) => {
  const [levelSelected, setLevelSelected] = useState<string | undefined>("250");
  const [isAnnually, setIsAnnually] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [planOpen, setPlanOpen] = useState<number | null>(null);
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [getStartedOpen, setGetStartedOpen] = useState<boolean>(false);

  const levelSelectedData = useMemo(() => {
    if (levelSelected) {
      return data?.cards.find(
        (card) => card.numberOfUsersPerPlan === levelSelected,
      );
    }
  }, [levelSelected]);

  return (
    <div className={customModuleStyle(data?.withBackground)}>
      <div className="px-10 lg:mx-auto xl:w-[1270px] xl:p-0">
        {data?.showToggle && (
          <div className="mb-10 hidden items-baseline lg:flex">
            <div className="book-consultation-container dangerouslySetInnerHTML mr-4 font-roboto text-2xl text-gray-border">
              <div
                dangerouslySetInnerHTML={
                  { __html: data?.toggleLeftText || "" } as { __html: string }
                }
              />
            </div>

            <label className="relative inline-flex cursor-pointer items-center">
              <input
                type="checkbox"
                value=""
                className="peer sr-only"
                checked={isAnnually}
                onChange={() => {
                  setIsAnnually((current) => !current);
                }}
              />
              <div className="peer h-6 w-11 rounded-full border border-gray-dark bg-white after:absolute after:left-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-border after:bg-blue after:transition-all after:content-[''] peer-checked:bg-white peer-checked:after:translate-x-full peer-checked:after:border-blue peer-focus:ring-4 peer-focus:ring-white"></div>
            </label>

            <div className="book-consultation-container dangerouslySetInnerHTML ml-4 font-roboto text-2xl text-blue-dark">
              <div
                dangerouslySetInnerHTML={
                  { __html: data?.toggleRightText || "" } as { __html: string }
                }
              />
            </div>
          </div>
        )}
        <div className="custom-border hidden flex-col flex-nowrap justify-between rounded-bl-[200px] rounded-tr-[200px] border border-gray-light bg-white p-2 lg:flex lg:flex-row lg:flex-wrap">
          {data?.cards?.map((card, index) => (
            <PricingModuleCard
              data={card}
              key={index}
              isAnnually={isAnnually}
              setIsDialogOpen={setIsDialogOpen}
              ctaLinkLabel={data?.ctaLinkLabel}
              setPlanOpen={setPlanOpen}
              planOpen={planOpen}
              formOpen={formOpen}
              setFormOpen={setFormOpen}
              getStartedOpen={getStartedOpen}
              setGetStartedOpen={setGetStartedOpen}
            />
          ))}
        </div>

        <div className="flex justify-center rounded-md lg:hidden" role="group">
          {data?.cards.map((card, index) => (
            <button
              key={index}
              type="button"
              onClick={() => setLevelSelected(card?.numberOfUsersPerPlan)}
              className={
                index === 0 && levelSelected === card?.numberOfUsersPerPlan
                  ? "rounded-l-3xl border border-gray-light bg-gray-light px-4 py-2 text-lg font-medium text-gray-dark"
                  : index === 0 && levelSelected !== card?.numberOfUsersPerPlan
                  ? "rounded-l-3xl border border-gray-light bg-white px-4 py-2 text-lg font-medium text-gray-dark"
                  : index === data?.cards?.length - 1 &&
                    levelSelected === card?.numberOfUsersPerPlan
                  ? "rounded-r-3xl border border-gray-light bg-gray-light px-4 py-2 text-lg font-medium text-gray-dark"
                  : index === data?.cards?.length - 1 &&
                    levelSelected !== card?.numberOfUsersPerPlan
                  ? "rounded-r-3xl border border-gray-light bg-white px-4 py-2 text-lg font-medium text-gray-dark"
                  : index !== data?.cards?.length - 1 &&
                    index !== 0 &&
                    levelSelected === card?.numberOfUsersPerPlan
                  ? "border border-gray-light bg-gray-light px-4 py-2 text-lg font-medium text-gray-dark "
                  : "border border-gray-light bg-white px-4 py-2 text-lg font-medium text-gray-dark"
              }
            >
              {card?.numberOfUsersPerPlan}
            </button>
          ))}
        </div>

        {levelSelectedData && (
          <div className="mx-auto mt-10 w-full md:w-1/2 lg:hidden">
            <PricingModuleCard
              data={levelSelectedData}
              setIsDialogOpen={setIsDialogOpen}
              ctaLinkLabel={data?.ctaLinkLabel}
              setPlanOpen={setPlanOpen}
              planOpen={planOpen}
              formOpen={formOpen}
              setFormOpen={setFormOpen}
              getStartedOpen={getStartedOpen}
              setGetStartedOpen={setGetStartedOpen}
            />
          </div>
        )}
      </div>

      {isDialogOpen && (
        <PricingDialog
          data={data}
          handleClose={() => setIsDialogOpen(false)}
          planOpen={planOpen}
          formOpen={formOpen}
          getStartedOpen={getStartedOpen}
          setFormOpen={setFormOpen}
          setGetStartedOpen={setGetStartedOpen}
        />
      )}
    </div>
  );
};

export default PricingModule;
