import React from "react";

const FacebookSvg = () => {
    return (
        <svg width="70" height="72" viewBox="0 0 70 72" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M43.2238 20.7344H38.8183C34.7638 20.7344 31.4758 24.0223 31.4758 28.0769V32.4824H27.0703V38.3564H31.4758V50.1044H37.3498V38.3564H41.7553L43.2238 32.4824H37.3498V28.0769C37.3498 27.2663 38.0077 26.6084 38.8183 26.6084H43.2238V20.7344Z"
                fill="#0A0F3C"/>
        </svg>
    );
};

export default FacebookSvg;


