import React from "react";

const ReadMore = () => {
  return (
    <div className="mx-3 -mt-[2px]">
      <svg
        width="8"
        height="13"
        viewBox="0 0 8 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 11.5L6 6.5L1 1.5"
          stroke="#1930FF"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default ReadMore;
