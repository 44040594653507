import React from "react";

const Clock = () => {
  return (
    <svg
      className="mr-2 h-4 w-4 text-gray-secondary"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.58065 0 0 3.58065 0 8C0 12.4194 3.58065 16 8 16C12.4194 16 16 12.4194 16 8C16 3.58065 12.4194 0 8 0ZM8 14.4516C4.41935 14.4516 1.54839 11.5806 1.54839 8C1.54839 4.45161 4.41935 1.54839 8 1.54839C11.5484 1.54839 14.4516 4.45161 14.4516 8C14.4516 11.5806 11.5484 14.4516 8 14.4516ZM9.96774 11.0968C10.1613 11.2258 10.3871 11.1935 10.5161 11L11.129 10.1935C11.2581 10 11.2258 9.77419 11.0323 9.64516L8.90323 8.06452V3.48387C8.90323 3.29032 8.70968 3.09677 8.51613 3.09677H7.48387C7.25806 3.09677 7.09677 3.29032 7.09677 3.48387V8.80645C7.09677 8.90323 7.12903 9.03226 7.22581 9.09677L9.96774 11.0968Z"
        fill="#4F5059"
      />
    </svg>
  );
};

export default Clock;
