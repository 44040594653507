import Background2Image from "../../components/svg/Background2Image";
import React from "react";
import { clientSaySvg } from "../../components/svg/clientSaySvg";
import { IContentModules } from "../../globalTypes";
import VerticalCarousel from "../../components/VerticalCarousel/VerticalCarousel";
import LinkComp from "../../components/LinkComp/LinkComp";

interface IProp {
  data: IContentModules;
}
const SuccessStoriesModule = ({ data }: IProp) => {
  return (
    <div className="w-full bg-blue-secondary">
      <div className="w-full">
        <div className="hidden w-full xl:block">
          <Background2Image />
        </div>

        <div className="mx-0 px-10 py-[40px] xl:mx-auto xl:w-container xl:px-0 xl:py-[50px]">
          <h1 className="text-xl font-bold text-white xl:text-40 ">
            {data?.heading}
          </h1>
          <VerticalCarousel data={data} />
          <div className="w-[200px]">
            <LinkComp
              linkUrl={data?.link?.url || ""}
              target={data?.link?.target}
              label={
                <span className="read-more-btn flex items-center pt-[30px] font-roboto text-sm text-white xl:pt-[50px] xl:text-base ">
                  <span className="read-more-white-hover">
                    {data?.link?.label}
                  </span>
                  {clientSaySvg}
                </span>
              }
              customStyle=""
              isExternal={data?.link?.isExternal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessStoriesModule;
