import Card from "../../components/Card/Card";
import { FeatureBlocks, IContentModules } from "../../globalTypes";
import classNames from "classnames";
interface IProp {
  data: IContentModules;
}
const env = window._env_ ?? {}

const DetailedFeaturesModule = ({ data }: IProp) => {
  const baseUrl = env.REACT_APP_IMG_BASE_URL;
  const customDesktopModuleStyle = classNames(
    "p-[50px] rounded-3xl h-[700px] absolute xl:w-container w-[92%]",
    {
      "bg-blue-light": data?.withBackground,
      "bg-white": !data?.withBackground,
    },
  );

  const customMobileModuleStyle = classNames(
    "py-[40px] px-[20px] rounded-[20px]",
    {
      "bg-blue-light": data?.withBackground,
      "bg-white": !data?.withBackground,
    },
  );
  return (
    <div className="bg-white py-[40px] lg:py-[50px]">
      <div className="hidden px-10 lg:block xl:mx-auto xl:w-container xl:px-0">
        <div className={customDesktopModuleStyle}>
          <div className="pb-[30px] font-roboto text-2xl font-bold text-blue-dark xl:text-40 ">
            {data.heading}
          </div>
          <div className="pb-[30px] text-xl text-gray lg:text-30">
            {data.description}
          </div>
        </div>
        <div className="flex grow flex-col flex-nowrap justify-between px-10 pt-[26%] lg:flex-row lg:flex-wrap xl:w-container">
          {data?.featureBlocks?.map((item: FeatureBlocks, index) => (
            <Card
              key={index}
              customClassNames="bg-white lg:mx-4 lg:w-[29.5%] w-full p-[30px] rounded-[40px]"
              customClassNameParagraph="dangerouslySetInnerHTML"
              image={baseUrl + item.icon.url}
              title={item.heading}
              paragraph={item.description}
              link={item.link}
            />
          ))}
        </div>
      </div>

      {/* mobile version */}
      <div className="block px-[10px] lg:hidden">
        <div className={customMobileModuleStyle}>
          <div className="pb-[30px] font-roboto text-25 font-bold text-blue-dark">
            {data.heading}
          </div>
          <div className="text-xl text-blue-dark">{data.description}</div>
          <div className="flex flex-col flex-nowrap justify-between">
            {data?.featureBlocks?.map((item: FeatureBlocks, index) => (
              <Card
                key={index}
                customClassNames="bg-white mt-[30px] lg:w-[29.5%] w-full p-[30px] rounded-[40px]"
                customClassNameParagraph="dangerouslySetInnerHTML"
                image={baseUrl + item.icon.url}
                title={item.heading}
                paragraph={item.description}
                link={item.link}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default DetailedFeaturesModule;
