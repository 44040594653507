import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

const Layout: React.FC = () => {
  return (
    <div className="overflow-x-hidden pt-16">
      <ScrollToTop />
      <Outlet />
    </div>
  );
};

export default Layout;
