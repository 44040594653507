import React, { useEffect, useState } from "react";
import { IContentModules, IECourse, IECourses } from "../../globalTypes";
import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { getNextPageData } from "../ContentPage/contentApi";
import VideoCard from "../../components/VideoCard/VideoCard";
import Dialog from "../../components/Dialod/Dialog";
import {
  customCardsOrderStyle,
  customModuleStyle,
  darkButtonStyle,
} from "./CustomStyles";

interface IProps {
  data: IContentModules;
}

const VideosModule = ({ data }: IProps) => {
  const [videos, setVideos] = useState<IECourse[]>([]);
  const [nextPage, setNextPage] = useState<number>(1);
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);
  const [shouldUpdateLink, setShouldUpdateLink] = useState<boolean>(false);
  const [newLink, setNewLink] = useState<string>("");
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [selectedVideo, setSelectedVideo] = useState<IECourse | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);

  useEffect(() => {
    if (data?.videos?.results) {
      setVideos(data?.videos?.results);
      setHasNextPage(!!data?.videos?.hasNextPage);
    }
  }, [data]);

  useEffect(() => {
    let nextPageLink = "";
    if (shouldUpdateLink) {
      if (data?.videos?.filter?.currentPageId) {
        nextPageLink = `${nextPage}&currentPageId=${data?.videos?.filter?.currentPageId}`;
      }
      if (data?.videos?.filter?.moduleKey) {
        nextPageLink = `${nextPage}&currentPageId=${data?.videos?.filter?.moduleKey}`;
      }
      if (data?.apiUrl) {
        nextPageLink = data?.apiUrl.replace("page=2", `page=${nextPage}`);
      }

      setNewLink(nextPageLink);
      setShouldFetch(true);
    }
  }, [data, nextPage, shouldUpdateLink]);

  useQuery<AxiosResponse<IECourses>, Error>(
    ["query-next-page", shouldFetch],
    async () => await getNextPageData(newLink),
    {
      enabled: shouldFetch,
      onSuccess: (res) => {
        if (nextPage === 1) {
          setVideos(res.data.results);
          setShouldFetch(false);
          setHasNextPage(res.data?.hasNextPage || false);
        } else {
          const newData = [...videos, ...res.data.results];
          setVideos(newData);
          setShouldFetch(false);
          setHasNextPage(res.data?.hasNextPage || false);
        }
      },
    },
  );

  const onCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedVideo(null);
  };

  return (
    <div className={`${customModuleStyle(data?.withBackground)} px-10 xl:px-0`}>
      <div className="lg:mx-auto xl:w-container">
        <div className="pb-[30px] font-roboto text-xl font-bold text-blue-dark xl:pb-[50px] xl:text-40 ">
          {data?.heading}
        </div>

        <div className={customCardsOrderStyle}>
          {videos?.map((item: IECourse, index: number) => (
            <div
              key={index}
              onClick={() => {
                setSelectedVideo(item);
                setIsDialogOpen(true);
              }}
              className="w-full cursor-pointer"
            >
              <VideoCard data={item} />
            </div>
          ))}
        </div>
        {hasNextPage && (
          <div
            key={data?.buttonText}
            className="mt-[30px] flex w-full items-center justify-center xl:mt-[50px]"
          >
            <button
              onClick={() => {
                setNextPage((current) => current + 1);
                setShouldUpdateLink(true);
              }}
              className={`${darkButtonStyle} px-[30px] py-[15px]`}
            >
              {data?.buttonText}
            </button>
          </div>
        )}
      </div>

      {isDialogOpen && (
        <Dialog data={selectedVideo} handleClose={onCloseDialog} />
      )}
    </div>
  );
};

export default VideosModule;
