import React, { useMemo } from "react";
import { IContentModules } from "../../globalTypes";
import { getLink } from "../../components/functions/getLink";
import { customModuleStyle, lightButtonStyle } from "./CustomStyles";
import LinkComp from "../../components/LinkComp/LinkComp";
import { useMediaQuery } from "usehooks-ts";

interface IProps {
  data: IContentModules;
}
const env = window._env_ ?? {}

const ResourceModule = ({ data }: IProps) => {
  const baseUrl = env.REACT_APP_IMG_BASE_URL;
  const isBigScreen = useMediaQuery("(min-width: 1024px)");

  const linkURL = useMemo(() => {
    if (data?.resource?.absoluteUrl) {
      return getLink(data?.resource?.absoluteUrl || "");
    } else {
      return "/";
    }
  }, [data]);

  return (
    <div className={`${customModuleStyle(data?.withBackground)} px-10 xl:px-0`}>
      <div className="lg:mx-auto xl:w-container">
        <h2 className="pb-[10px] font-roboto text-25/35 font-bold text-blue-dark xl:pb-[50px] xl:text-50">
          {data?.heading}
        </h2>

        <div className="card-hover relative flex w-full flex-col items-start justify-start overflow-hidden lg:flex-row">
          <div className="relative w-full overflow-hidden lg:mr-8 lg:h-[330px] lg:w-[48%] xl:h-[370px]">
            {data?.resource?.thumbnail?.url ? (
              <img
                src={baseUrl + data?.resource?.thumbnail?.url}
                alt={data?.image?.altText}
                className={
                  isBigScreen
                    ? "hover-img absolute left-0 top-0 object-cover transition-all duration-500 ease-out"
                    : "w-full object-cover"
                }
              />
            ) : (
              <div className="mr-8 flex h-full w-full grow items-center justify-center rounded bg-gray-300 object-cover p-20 lg:w-[48%]">
                <svg
                  className="h-full w-1/2 text-gray-200 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>
            )}
          </div>

          <div className="my-5 w-full lg:w-[48%] xl:mt-0">
            <div className="pb-[10px] font-roboto text-xl font-bold text-blue-dark xl:text-2xl">
              {data?.resource?.heading}
            </div>
            <div className="pb-[10px] text-base font-bold text-gray-border">
              {data?.resource?.subHeading}
            </div>
            <div className="mb-8 text-base text-gray">
              {data?.resource?.summary}
            </div>
            <LinkComp
              linkUrl={linkURL}
              target={"_blank"}
              label={data?.buttonText}
              customStyle={`${lightButtonStyle} px-8 py-2.5 lg:px-9 w-[140px]`}
              shouldShowArrow={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceModule;
