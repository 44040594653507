import React from 'react';

const Check = () => {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2845 0.175112L5.07659 9.4599L1.71554 6.03547C1.54048 5.89426 1.26039 5.89426 1.12035 6.03547L0.105033 7.05927C-0.0350109 7.20049 -0.0350109 7.48291 0.105033 7.65943L4.7965 12.3548C4.97155 12.5313 5.21663 12.5313 5.39169 12.3548L15.895 1.76376C16.035 1.62255 16.035 1.34012 15.895 1.16361L14.8797 0.175112C14.7396 -0.00140462 14.4595 -0.00140462 14.2845 0.175112Z"
        fill="#1930FF"
      />
    </svg>
  );
};

export default Check;
