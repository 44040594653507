import React from "react"
import { IContentModules } from "../../globalTypes";
import {
  customModuleStyle
} from "./CustomStyles";
import useMediaQuery from '../../components/hooks/useMediaQuery';
import Ellipse from '../../components/svg/Ellipse_mask.svg'
import Time from '../../components/svg/Time.svg'
interface IProp {
  data: IContentModules;
  pageTemplate?: string;
}

const TimesInfoModule = ({ data }: IProp) => {
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  return (
    <>
      <div className='bg-blue-light w-full h-[200px] p-0 relative z-0'></div>
      <div className={`${customModuleStyle(data?.withBackground)} pt-0 lg:px-auto px-[10px] `}>
        <div className="relative z-10 -mt-48 w-full mx-auto bg-gray-light rounded-[20px] xl:w-container flex flex-col lg:flex-row lg:justify-evenly lg:gap-[30px] lg:px-[40px] px-[18px] py-[30px] lg:py-[50px]  overflow-hidden">
          {isSmallScreen && <div className='relative z-10 text-[40px] leading-[44px] font-bold text-blue-dark'>
            {data?.heading}
          </div>}
          {isSmallScreen && <div className='w-[1000px] absolute -top-[40%] left-[38%] z-0'>
            <img src={Ellipse} alt="Ellipse" />
          </div>}
          <div className="flex flex-col text-blue-dark lg:w-[32%]">
            {data?.cards?.map((card, index) => (
              <div className='relative flex items-start pb-[20px] last:pb-0 ' key={index}>
                <div className='absolute top-[16px] lg:top-0 w-[60px] h-[60px]'>
                  <img src={Time} alt="time" />
                </div>
                <div key={index} className="relative z-20 ml-[40px]">
                  <div className='flex items-baseline'>
                    <span className="lg:text-[150px] text-[100px] leading-[114px] font-extrabold">{card.time}</span>
                    <span className="text-[30px] font-extrabold">{card.timeUnit}</span>
                  </div>
                  <div className="lg:leading-[34px] lg:text-[30px] text-[24px] text-gray">{card.description}</div>
                </div>
              </div>
            ))}
          </div>

          <div className="lg:w-[50%] flex pt-[30px] lg:p-0">
            <div>
              {!isSmallScreen && <div className='relative z-20 text-[40px] leading-[44px] font-bold text=blue-dark lg:pb-[20px]'>
                {data?.heading}
              </div>}
              <div className='text-gray lg:pb-[30px] relative z-20'>
                {data.leftDescription}
              </div>
              <div className='text-gray relative z-20'>
                {data.rightDescription}
              </div>
            </div>
            {!isSmallScreen && <div className='w-[1000px] absolute -top-[64%] left-[68%] z-0'>
              <img src={Ellipse} alt="Ellipse" />
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default TimesInfoModule;
