import React from "react";
import { IContentModules } from "../../globalTypes";

interface IProps {
  data: IContentModules;
}
const env = window._env_ ?? {}

const ImageModule = ({ data }: IProps) => {
  const baseUrl = env.REACT_APP_IMG_BASE_URL;
  return (
    <div className="mx-[30px] flex justify-end lg:mx-auto lg:w-[770px] xl:mx-auto">
      {data?.image?.url && (
        <img src={baseUrl + data?.image?.url} className="relative right-0 " />
      )}
    </div>
  );
};

export default ImageModule;
