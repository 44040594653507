import React from "react";
import { IContentModules } from "../../globalTypes";
import { specialBannerModuleBg } from "./CustomStyles";

interface IProp {
  data: IContentModules;
  pageTemplate?: string;
}

const SpecialBannerModule = ({ data, pageTemplate }: IProp) => {
  return (
    <div className="w-full bg-blue-bg px-10 xl:px-0">
      <div className={specialBannerModuleBg(pageTemplate)}></div>
      <div className="py-[40px] lg:mx-auto lg:w-full lg:py-[50px] xl:w-container">
        <div className="md:text-blue-white xl:text-40 text-30 pb-[30px] font-bold text-white ">
          {data.heading}
        </div>
        <div className=" xl:text-30 text-20/26 w-full text-white lg:w-[85%] xl:w-container">
          {data.description}
        </div>
      </div>
    </div>
  );
};

export default SpecialBannerModule;
