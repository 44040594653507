import React from "react";
import { IContentModules } from "../../globalTypes";
import { customModuleStyle } from "./CustomStyles";

interface IProps {
  data: IContentModules;
}
const env = window._env_ ?? {}

const ParagraphWithImageModule = ({ data }: IProps) => {
  const baseUrl = env.REACT_APP_IMG_BASE_URL;

  return (
    <div
      className={`${customModuleStyle(data?.withBackground)} px-[30px] xl:px-0`}
    >
      <div className="w-full lg:mx-auto xl:w-[770px]">
        <div className="inline-block w-full">
          {data?.image?.url && (
            <img
              src={baseUrl + data?.image?.url}
              className="float-right mb-[30px] ml-[30px] inline w-full rounded-[10px] sm:max-w-[370px]"
            />
          )}
          <div className="dangerouslySetInnerHTML font-roboto text-2xl text-gray-secondary">
            <div
              dangerouslySetInnerHTML={
                { __html: data?.text || "" } as { __html: string }
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParagraphWithImageModule;
