import { IContentModules, ICustomerQuote } from "../../globalTypes";
import TestimonialsCard from "../../components/TestimonialsCard/TestimonialsCard";
import { customModuleStyle } from "./CustomStyles";

interface IProp {
  data: IContentModules;
}

const TestimonialsGridModule = ({ data }: IProp) => {
  return (
    <div
      className={`${customModuleStyle(
        data?.withBackground,
      )} h-full px-10 xl:px-0`}
    >
      <div className="w-full xl:mx-auto xl:w-container">
        <div className="xl:text-40 text-25/35 pb-[30px] font-bold text-blue-dark xl:pb-[50px] ">
          {data.heading}
        </div>
        <div className="grid gap-x-[30px] gap-y-[50px] xs:grid-cols-1 grid-cols-1 lg:grid-cols-3">
          {data?.customerQuotes.map((item: ICustomerQuote, index) => (
            <TestimonialsCard
              key={index}
              authorDesignation={item.authorDesignation}
              authorName={item.authorName}
              content={item.content}
              customerName={item.customerName}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsGridModule;
