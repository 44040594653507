import React from 'react';

const HamburgerSvg = () => {
  return (
    <svg
      width="27"
      height="18"
      viewBox="0 0 27 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1H26"
        stroke="#0A0F3C"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1 9H26"
        stroke="#0A0F3C"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1 17H26"
        stroke="#0A0F3C"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default HamburgerSvg;
