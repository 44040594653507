import React, { useState } from "react";
import {
  IApiResponse,
  ICampaignFormValues,
  IContentModules,
} from "../../globalTypes";
import InputField from "../../components/InputField/InputField";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { submitForm } from "../ContentPage/contentApi";
import classNames from "classnames";
import { useMediaQuery } from "usehooks-ts";
import ReCAPTCHAComp from "../../components/ReCAPTCHA/ReCAPTCHAComp";
import { AxiosError } from "axios";
import AgreeToTermsCheckbox from "../../components/AgreeToTermsCheckbox/AgreeToTermsCheckbox";
import ResponceFormData from "../../components/ResponceForm/ResponceFormData";

interface IProps {
  data: IContentModules;
}

const validationSchema = Yup.object({
  email: Yup.string().email("Not a valid email").required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  company: Yup.string(),
  isVerified: Yup.boolean().oneOf([true], "Required"),
  isAgreedToTerms: Yup.boolean().oneOf([true], "Required"),
});

const CampaignFormModule = ({ data }: IProps) => {
  const [langSelected, setLangSelected] = useState<string | null>(null);
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    isVerified: false,
    isAgreedToTerms: false,
  };
  const [respData, setRespData] = useState<IApiResponse | null>(null);
  const isBigScreen = useMediaQuery("(min-width: 1024px)");

  const customButtonStyle = classNames("grid gap-[30px]", {
    "grid-cols-2": isBigScreen && data?.languageCodes?.length > 1,
    "grid-cols-1": isBigScreen && data?.languageCodes?.length == 1,
    "grid-rows-2": !isBigScreen && data?.languageCodes?.length > 1,
    "grid-rows-1": !isBigScreen && data?.languageCodes?.length == 1,
  });

  const handleSubmit = async (
    values: ICampaignFormValues,
    actions: FormikHelpers<ICampaignFormValues>,
  ) => {
    actions.setSubmitting(true);

    const { firstName, lastName, email, company } = values;

    if (data?.campaignId && langSelected) {
      const formData = new FormData();
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("email", email);
      formData.append("company", company);
      formData.append("LanguageCode", langSelected);
      formData.append("campaignId", data.campaignId.toString());

      try {
        const status = await submitForm(formData, data?.apiUrl);

        if (status?.data) {
          if (status?.data?.returnUrl) {
            window.location.href = status?.data?.returnUrl;
          }
        }
      } catch (error) {
        const err = error as AxiosError;
        const ErrData = err?.response?.data as IApiResponse;
        setRespData(ErrData);
        actions.resetForm();
        actions.setSubmitting(false);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className="relative lg:min-h-[550px]">
      <div className={data?.withBackground ? "customRightShape" : ""}></div>
      <div className="z-20 w-full">
        <div className="flex flex-col items-start justify-between px-[30px] py-6 lg:mx-auto lg:flex-row xl:w-container xl:px-0">
          <div className="z-20 my-4 h-auto w-full rounded-3xl border border-gray-light bg-white px-[16px] py-[30px] lg:mx-auto lg:w-[670px] lg:p-[50px]">
            {!respData && (
              <form onSubmit={formik.handleSubmit}>
                <h2 className="pb-4 font-roboto text-2xl font-bold xl:text-3xl">
                  {data?.heading}
                </h2>
                <InputField
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  isRequired={true}
                  placeholder={data?.firstNamePlaceholder || ""}
                  label={data?.firstNameLabel || ""}
                  type="text"
                  hasError={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  errMsg={
                    formik.touched.firstName && formik.errors.firstName
                      ? formik.errors.firstName
                      : ""
                  }
                  onChange={(e) => formik.handleChange(e)}
                />

                <InputField
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName}
                  isRequired={true}
                  placeholder={data?.lastNamePlaceholder || ""}
                  label={data?.lastNameLabel || ""}
                  type="text"
                  hasError={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  errMsg={
                    formik.touched.lastName && formik.errors.lastName
                      ? formik.errors.lastName
                      : ""
                  }
                  onChange={(e) => formik.handleChange(e)}
                />

                <InputField
                  id="email"
                  name="email"
                  value={formik.values.email}
                  isRequired={true}
                  placeholder={data?.emailPlaceholder || ""}
                  label={data?.emailLabel || ""}
                  type="email"
                  hasError={
                    formik.touched.email && Boolean(formik.errors.email)
                  }
                  errMsg={
                    formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : ""
                  }
                  onChange={(e) => formik.handleChange(e)}
                />

                <InputField
                  id="company"
                  name="company"
                  value={formik.values.company}
                  isRequired={false}
                  placeholder={data?.companyPlaceholder || ""}
                  label={data?.companyLabel || ""}
                  onChange={(e) => formik.handleChange(e)}
                />

                <AgreeToTermsCheckbox
                  isChecked={formik.values.isAgreedToTerms}
                  onChange={(e) =>
                    formik.setFieldValue("isAgreedToTerms", e.target.checked)
                  }
                  label={data?.agreeToTermsText || ""}
                  hasError={
                    formik.touched.isAgreedToTerms &&
                    Boolean(formik.errors.isAgreedToTerms)
                  }
                  errMsg={
                    formik.touched.isAgreedToTerms &&
                    formik.errors.isAgreedToTerms
                      ? formik.errors.isAgreedToTerms
                      : ""
                  }
                />

                <ReCAPTCHAComp
                  label={data?.reCaptchaLabel || ""}
                  onChange={() => formik.setFieldValue("isVerified", true)}
                  hasError={
                    formik.touched.isVerified &&
                    Boolean(formik.errors.isVerified)
                  }
                  errMsg={
                    formik.touched.isVerified && formik.errors.isVerified
                      ? formik.errors.isVerified
                      : ""
                  }
                />

                <div className={customButtonStyle}>
                  {data?.languageCodes?.map((language, index) => (
                    <button
                      type="submit"
                      className="flex w-full items-center justify-center whitespace-nowrap rounded-sm border border-blue bg-blue p-[15px] font-semibold text-white hover:border-blue-hover hover:bg-blue-hover"
                      key={index}
                      onClick={() => setLangSelected(language)}
                    >
                      {data?.buttonText} {language}
                    </button>
                  ))}
                </div>
              </form>
            )}

            {respData && (
              <ResponceFormData respData={respData} setRespData={setRespData} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignFormModule;
