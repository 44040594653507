import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLink } from "../../components/functions/getLink";
import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { I404Resp, getContent } from "./Page404Api";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const env = window._env_ ?? {}

const Page404 = () => {
  const [pageData, setPageData] = useState<I404Resp | null>(null);
  const [err, setErr] = useState<string>("");

  const baseUrl = env.REACT_APP_IMG_BASE_URL;
  const navigate = useNavigate();

  const { isLoading, isError } = useQuery<AxiosResponse<I404Resp>, Error>(
    ["query-content"],
    async () => await getContent("/page-404"),
    {
      onSuccess: (res) => {
        setPageData(res.data);
        setErr("");
      },
      onError: (err) => {
        setErr(err.message);
      },
    },
  );

  if (isLoading) {
    return <div></div>;
  }

  if (isError) {
    return <div>{err}</div>;
  }

  return (
    <div className="w-full overflow-x-hidden pt-[50px] lg:pt-[150px]">
      {pageData?.header && <Header data={pageData.header} />}
      {pageData && (
        <div className="mx-auto flex w-full  flex-col items-center justify-center px-10 md:w-[500px] lg:px-0">
          {pageData?.image?.url && (
            <img
              src={baseUrl + pageData?.image?.url}
              alt={pageData?.image?.altText}
              className="w-[90%] pb-[30px]"
            />
          )}
          {pageData?.heading && (
            <div className="pb-[10px] text-3xl font-bold lg:text-[80px] lg:leading-[90px]">
              {pageData?.heading}
            </div>
          )}
          {pageData?.subHeading && (
            <div className="pb-[10px] text-xl lg:text-30">
              {pageData?.subHeading}
            </div>
          )}
          {pageData?.description && (
            <div className="pb-[30px] text-center text-lg leading-[26px] lg:text-[20px]">
              {pageData?.description}
            </div>
          )}
          {pageData?.link?.url && (
            <div className="pb-[30px] lg:pb-[100px]">
              <button
                onClick={() => {
                  const linkURL = getLink(pageData?.link?.url || "");
                  navigate(linkURL);
                }}
                className="flex items-center justify-start"
              >
                <span className="rounded-sm bg-blue px-[30px] py-[15px] font-medium text-white ">
                  {pageData?.link?.label}
                </span>
              </button>
            </div>
          )}
        </div>
      )}
      {pageData?.footer && <Footer data={pageData.footer} />}
    </div>
  );
};

export default Page404;
