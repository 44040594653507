import React, { useEffect, useState } from "react";
import { IContentModules, IECourse, IECourses } from "../../globalTypes";
import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { getNextPageData } from "../ContentPage/contentApi";
import BlogCard from "../../components/BlogCard/BlogCard";
import {
  blogSButtonStyle,
  customModuleStyle,
  darkButtonStyle,
  gridCardsOrderStyle,
} from "./CustomStyles";

interface IProps {
  data: IContentModules;
}

const BlogItemsByCategoryModule = ({ data }: IProps) => {
  const [blogs, setBlogs] = useState<IECourse[]>([]);
  const [nextPage, setNextPage] = useState<number>(1);
  const [categoryID, setCategoryID] = useState<number | null>(null);
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);
  const [shouldUpdateLink, setShouldUpdateLink] = useState<boolean>(false);
  const [newLink, setNewLink] = useState<string>("");
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);

  useEffect(() => {
    if (data?.blogs) {
      setBlogs(data?.blogs?.results);
      setHasNextPage(!!data?.blogs?.hasNextPage || false);
    }

    if (data?.resources) {
      setBlogs(data?.resources?.results);
      setHasNextPage(!!data?.resources?.hasNextPage || false);
    }

    return () => {
      setHasNextPage(false);
    };
  }, [data]);

  useEffect(() => {
    let nextPageLink = "";
    if (shouldUpdateLink) {
      if (data?.apiUrl) {
        if (data?.resources?.filter?.currentPageId) {
          nextPageLink = `${nextPage}&currentPageId=${data?.resources?.filter?.currentPageId}`;
        }
        if (data?.resources?.filter?.moduleKey) {
          nextPageLink = `${nextPage}&currentPageId=${data?.resources?.filter?.moduleKey}`;
        }
        if (categoryID) {
          nextPageLink = `${nextPageLink}&categoryId=${categoryID}`;
        }
        nextPageLink = data?.apiUrl.replace("page=2", `page=${nextPage}`);
      }
      setNewLink(nextPageLink);
      setShouldFetch(true);
    }
  }, [data, nextPage, categoryID, shouldUpdateLink]);

  useQuery<AxiosResponse<IECourses>, Error>(
    ["query-next-page", shouldFetch],
    async () => await getNextPageData(newLink),
    {
      enabled: shouldFetch,
      onSuccess: (res) => {
        if (nextPage === 1) {
          setBlogs(res.data.results);
          setShouldFetch(false);
          setHasNextPage(res?.data?.hasNextPage || false);
        } else {
          const newData = [...blogs, ...res.data.results];
          setBlogs(newData);
          setShouldFetch(false);
          setHasNextPage(res?.data?.hasNextPage || false);
        }
      },
    },
  );

  return (
    <div className={`${customModuleStyle(data?.withBackground)} px-10 xl:px-0`}>
      <div className="lg:mx-auto xl:w-container">
        {data?.heading && (
          <div className="pb-[30px] font-roboto text-xl font-bold xl:pb-[50px] xl:text-40 ">
            {data?.heading}
          </div>
        )}

        {(data?.categories || data?.showAllLabel) && (
          <div className="mb-[30px] xl:mb-[50px]">
            <div className="flex flex-wrap justify-center text-20 font-bold">
              {data?.showAllLabel && (
                <button
                  onClick={() => {
                    setCategoryID(null);
                    setNextPage(1);
                    setShouldUpdateLink(true);
                  }}
                  className={!categoryID ? " text-blue" : " text-blue-dark"}
                >
                  {data?.showAllLabel}
                </button>
              )}
              {data?.categories?.map((category) => (
                <button
                  key={category?.id}
                  onClick={() => {
                    setCategoryID(category?.id);
                    setNextPage(1);
                    setShouldUpdateLink(true);
                  }}
                  className={
                    categoryID === category?.id
                      ? "pl-6 text-blue"
                      : "pl-6 text-blue-dark hover:text-blue"
                  }
                >
                  {category?.label}
                </button>
              ))}
            </div>
          </div>
        )}

        <div className={gridCardsOrderStyle}>
          {blogs?.map((item: IECourse, index: number) => (
            <BlogCard
              key={index}
              data={item}
              moduleName={data?.moduleName}
              customStyle="w-full"
            />
          ))}
        </div>

        <div key={data?.buttonText} className={blogSButtonStyle(hasNextPage)}>
          <button
            onClick={() => {
              setNextPage((current) => current + 1);
              setShouldUpdateLink(true);
            }}
            className={`${darkButtonStyle} mt-[50px] px-10 py-4`}
          >
            {data?.buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogItemsByCategoryModule;
