import classnames from "classnames";
import {
  IContentModules,
  ICustomerStoryDescriptionModuleData,
} from "../../globalTypes";
import CustomerStoriesDescriptionCard from "../../components/CustomerStoriesDescriptionCard/CustomerStoriesDescriptionCard";
import { customerStoryDescModuleWrapper } from "./CustomStyles";

interface IProps {
  data: IContentModules;
  customerStoryData?: ICustomerStoryDescriptionModuleData;
}

const CustomerStoryDescriptionModule = ({
  data,
  customerStoryData,
}: IProps) => {
  const customModuleStyle = classnames(
    "w-full px-10 py-[40px] lg:py-[50px] xl:mx-auto xl:w-container xl:px-0",
    {
      "bg-blue-light": data?.withBackground,
      "bg-white": !data?.withBackground,
    },
  );

  const env = window._env_ ?? {}
  const baseUrl = env.REACT_APP_IMG_BASE_URL;
  return (
    <div className={customModuleStyle}>
      <div className="flex w-full flex-col items-start justify-between pb-0 xl:flex-row  xl:pb-[50px]">
        <div className="mb-[30px] flex w-full flex-col rounded-3xl border border-solid border-gray-light p-[30px] lg:mr-[20px] xl:w-[34%] ">
          {customerStoryData?.customerLogo?.url && (
            <img
              src={baseUrl + customerStoryData?.customerLogo?.url}
              alt={customerStoryData?.customerLogo.altText}
              className="rounded-md pb-[10px]"
            />
          )}
          <div className="pt-[20px]">
            <div className="pb-[10px] text-xl font-bold text-blue-dark">
              {customerStoryData?.industryLabel}:
            </div>
            <div className="text-lg text-gray">{data?.industry}</div>
          </div>
          <div className="pt-[20px]">
            <div className="pb-[10px] text-xl font-bold text-blue-dark">
              {customerStoryData?.locationLabel}:
            </div>
            <div className="text-lg text-gray">{data?.location}</div>
          </div>
          <div className="pt-[20px]">
            <div className="pb-[10px] text-xl font-bold text-blue-dark">
              {customerStoryData?.useCaseLabel}:
            </div>
            <div className="text-lg text-gray">{data?.useCase}</div>
          </div>
        </div>

        <div className="-mx-10 bg-blue-light py-[30px] xl:w-[70%] xl:bg-transparent xl:px-0 xl:py-0">
          <div className="mx-10 pb-[10px] text-25 font-bold text-blue-dark xl:pb-5 xl:text-40">
            {customerStoryData?.summaryLabel}
          </div>
          <div className="mx-10 text-base text-gray-secondary">
            {customerStoryData?.summary}{" "}
          </div>
          {customerStoryData?.additionalSummary && (
            <div className="mx-10 mt-10 flex flex-col">
              <div className="pb-[10px] text-25 font-bold text-blue-dark xl:pb-5 xl:text-40 ">
                {customerStoryData?.additionalSummaryLabel}
              </div>
              <div className="text-base text-gray-secondary">
                {customerStoryData?.additionalSummary}{" "}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={customerStoryDescModuleWrapper}>
        {data?.cards?.map((item, index) => (
          <CustomerStoriesDescriptionCard
            key={index}
            heading={item?.heading || ""}
            description={item?.description || ""}
          />
        ))}
      </div>
    </div>
  );
};

export default CustomerStoryDescriptionModule;
