import React from "react";
import { ICard } from "../../globalTypes";
interface IProps {
  item: ICard;
  index: number;
}
const CardWithNumber = ({ item, index }: IProps) => {
  return (
    <div
      className="my-4 flex w-full items-start justify-start lg:w-1/2"
      key={index}
    >
      <div className="h-10 w-10 rounded-full bg-blue-periwinkle px-3 py-1 text-25 font-bold xl:h-14 xl:w-14 xl:px-4 xl:text-40 ">
        {index + 1}
      </div>
      <div className="mx-6 w-full ">
        <div className="pb-4 text-20 font-bold text-blue-dark xl:text-30">
          {item.heading}
        </div>
        <div className="text-base text-gray xl:text-lg">{item.description}</div>
      </div>
    </div>
  );
};

export default CardWithNumber;
