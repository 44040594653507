import React from "react";
import { IContentModules } from "../../globalTypes";
import ImageGrid from "../../components/ImageGrid/ImageGrid";
import { customModuleStyle } from "./CustomStyles";
interface IProps {
  data: IContentModules;
}
const TeamModule = ({ data }: IProps) => {
  return (
    <div className={`${customModuleStyle(data?.withBackground)} px-10 xl:px-0`}>
      <div className="w-full xl:mx-auto xl:w-container">
        <div className="lg:mx-auto xl:w-container ">
          <div className="pb-[10px] font-roboto text-25 font-bold text-blue-dark xl:pb-5 xl:text-40 ">
            {data?.heading}
          </div>
          <p className="pb-[30px] text-xl font-medium text-gray-secondary xl:pb-[50px] xl:text-30">
            {data?.subHeading}
          </p>
        </div>
        <div>
          <ImageGrid data={data} />
        </div>
      </div>
    </div>
  );
};
export default TeamModule;
