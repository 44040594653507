import React from "react";
import { useNavigate } from "react-router";
import { IApiResponse } from "../../globalTypes";
import { getLink } from "../../components/functions/getLink";

interface IProps {
  respData: IApiResponse | undefined;
  setRespData: React.Dispatch<React.SetStateAction<IApiResponse | null>>;
}

const env = window._env_ ?? {}

const ResponceFormData = ({ respData, setRespData }: IProps) => {
  const baseUrl = env.REACT_APP_IMG_BASE_URL;
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="pb-5 text-center text-xl font-bold lg:text-3xl">
        {respData?.heading}
      </div>
      <div className="px-2 text-center text-lg lg:text-xl">
        {respData?.description}
      </div>
      {respData?.image?.url && (
        <img
          src={baseUrl + respData?.image?.url}
          alt={respData?.image?.altText}
          className="py-10"
        />
      )}
      {respData?.link && (
        <button
          key={respData.link.label}
          onClick={() => {
            if (respData?.success) {
              const linkURL = getLink(respData?.link?.url || "");
              navigate(linkURL);
            } else {
              setRespData(null);
            }
          }}
          className="flex justify-center rounded-sm border border-blue-600 bg-white px-9 py-2.5 text-blue"
        >
          <span className="whitespace-nowrap">{respData.link.label}</span>
        </button>
      )}
    </div>
  );
};

export default ResponceFormData;
