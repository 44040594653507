import React from 'react';

const Briefcase = () => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 3H12V1.5C12 0.6875 11.3125 0 10.5 0H5.5C4.65625 0 4 0.6875 4 1.5V3H1.5C0.65625 3 0 3.6875 0 4.5V12.5C0 13.3438 0.65625 14 1.5 14H14.5C15.3125 14 16 13.3438 16 12.5V4.5C16 3.6875 15.3125 3 14.5 3ZM5.5 1.5H10.5V3H5.5V1.5ZM1.6875 4.5H14.3125C14.4062 4.5 14.5 4.59375 14.5 4.6875V7H1.5V4.6875C1.5 4.59375 1.5625 4.5 1.6875 4.5ZM14.3125 12.5H1.6875C1.5625 12.5 1.5 12.4375 1.5 12.3125V8.5H6V9.25C6 9.6875 6.3125 10 6.75 10H9.25C9.65625 10 10 9.6875 10 9.25V8.5H14.5V12.3125C14.5 12.4375 14.4062 12.5 14.3125 12.5Z"
        fill="#4F5059"
      />
    </svg>
  );
};

export default Briefcase;
