import {
  IContentModules,
  ICustomerStoryDescriptionModuleData,
  IImage,
} from "../../globalTypes";
import CustomerStoryDescriptionModule from "../Modules/CustomerStoryDescriptionModule";
import FeaturedBlockModule from "../Modules/FeaturedBlockModule";
import KeyFeaturesBlockModule from "../Modules/KeyFeaturesBlockModule";
import TestimonialsGridModule from "../Modules/TestimonialsGridModule";
import DetailedFeaturesModule from "../Modules/DetailedFeaturesModule";
import SpecialBannerFeaturesModule from "../Modules/SpecialBannerFeaturesModule";
import TeamModule from "../Modules/TeamModule";
import LogoShowcaseModule from "../Modules/LogoShowcaseModule";
import CustomerStoriesModule from "../Modules/CustomerStoriesModule";
import CallToActionModule from "../Modules/CallToActionModule";
import TopFeaturesModule from "../Modules/TopFeaturesModule";
import SuccessStoriesModule from "../Modules/SuccessStoriesModule";
import SpecificFeaturesBlockModule from "../Modules/SpecificFeaturesBlockModule";
import TestimonialsModule from "../Modules/TestimonialsModule";
import SpecialBannerModule from "../Modules/SpecialBannerModule";
import ExploreMoreModule from "../Modules/ExploreMoreModule";
import SimpleBannerModule from "../Modules/SimpleBannerModule";
import CoursesModule from "../Modules/CoursesModule";
import CardsWithNumbersModule from "../Modules/CardsWithNumbersModule";
import PricingInteractivityModule from "../Modules/PricingInteractivityModule";
import CourseDescriptionModule from "../Modules/CourseDescriptionModule";
import ImageSliderModule from "../Modules/ImageSliderModule";
import CoursesSliderModule from "../Modules/CoursesSliderModule";
import CourseFeaturesModule from "../Modules/CourseFeaturesModule";
import FeaturesBlockModule from "../Modules/FeaturesBlockModule";
import ContactFormModule from "../Modules/ContactFormModule";
import ContactsModule from "../Modules/ContactsModule";
import PricingModule from "../Modules/PricingModule/PricingModule";
import TopBlogsModule from "../Modules/TopBlogsModule";
import ParagraphModule from "../Modules/ParagraphModule";
import ImageModule from "../Modules/ImageModule";
import ResourceModule from "../Modules/ResourceModule";
import VideosModule from "../Modules/VideosModule";
import FaqModule from "../Modules/FaqModule";
import BlogItemsByCategoryModule from "../Modules/BlogItemsByCategoryModule";

import ResourceDownloadFormModule from "../Modules/ResourceDownloadFormModule";
import ParagraphWithImageModule from "../Modules/ParagraphWithImageModule";
import CampaignFormModule from "../Modules/CampaignFormModule";
import AnchorModule from "../Modules/AnchorModule";
import TimesInfoModule from "../Modules/TimesInfoModule";

interface IProp {
  module: IContentModules;
  index: number;
  customerStoryData?: ICustomerStoryDescriptionModuleData;
  pageTemplate: string;
  pricingModelLabel?: string;
  pricingModelIcon?: IImage;
  languagesLabel?: string;
  languagesIcon?: IImage;
  interactivityLevelLabel?: string;
  interactivityLevelIcon?: IImage;
  durationLabel?: string;
  durationIcon?: IImage;
  industryLabel?: string;
  industryIcon?: IImage;
}

const ContentModule = ({
  module,
  index,
  customerStoryData,
  pageTemplate,
  pricingModelLabel,
  pricingModelIcon,
  languagesLabel,
  languagesIcon,
  interactivityLevelLabel,
  interactivityLevelIcon,
  durationLabel,
  durationIcon,
  industryLabel,
  industryIcon,
}: IProp) => {
  return (
    <>
      {(() => {
        switch (module.moduleName) {
          case "FeaturedBlockModule":
            return <FeaturedBlockModule key={index} data={module} />;
          case "KeyFeaturesBlockModule":
            return <KeyFeaturesBlockModule key={index} data={module} />;
          case "TestimonialsGridModule":
            return <TestimonialsGridModule key={index} data={module} />;
          case "CustomerStoryDescriptionModule":
            return (
              <CustomerStoryDescriptionModule
                key={index}
                data={module}
                customerStoryData={customerStoryData}
              />
            );
          case "DetailedFeaturesModule":
            return <DetailedFeaturesModule key={index} data={module} />;
          case "CampaignFormModule":
            return <CampaignFormModule key={index} data={module} />;
          case "FeaturesBlockModule":
            return <FeaturesBlockModule key={index} data={module} />;
          case "ExploreMoreModule":
            return <ExploreMoreModule key={index} data={module} />;
          case "LogoShowcaseModule":
            return <LogoShowcaseModule key={index} data={module} />;
          case "CustomerStoriesModule":
            return <CustomerStoriesModule key={index} data={module} />;
          case "CallToActionModule":
            return <CallToActionModule key={index} data={module} />;
          case "TopFeaturesModule":
            return (
              <TopFeaturesModule
                key={index}
                data={module}
                pageTemplate={pageTemplate}
              />
            );
          case "SuccessStoriesModule":
            return <SuccessStoriesModule key={index} data={module} />;
          case "SpecificFeaturesBlockModule":
            return <SpecificFeaturesBlockModule key={index} data={module} />;
          case "TestimonialsModule":
            return (
              <TestimonialsModule
                key={index}
                data={module}
                pageTemplate={pageTemplate}
              />
            );
          case "SpecialBannerModule":
            return (
              <SpecialBannerModule
                key={index}
                data={module}
                pageTemplate={pageTemplate}
              />
            );
          case "SimpleBannerModule":
            return <SimpleBannerModule key={index} data={module} />;
          case "SpecialBannerFeaturesModule":
            return <SpecialBannerFeaturesModule key={index} data={module} />;
          case "TeamModule":
            return <TeamModule key={index} data={module} />;
          case "CoursesModule":
            return <CoursesModule key={index} data={module} />;
          case "CoursesGroupsModule":
            return <CoursesModule key={index} data={module} />;
          case "CardsWithNumbersModule":
            return <CardsWithNumbersModule key={index} data={module} />;
          case "PricingInteractivityModule":
            return <PricingInteractivityModule key={index} data={module} />;
          case "CourseDescriptionModule":
            return (
              <CourseDescriptionModule
                key={index}
                data={module}
                pricingModelLabel={pricingModelLabel}
                pricingModelIcon={pricingModelIcon}
                languagesLabel={languagesLabel}
                languagesIcon={languagesIcon}
                interactivityLevelLabel={interactivityLevelLabel}
                interactivityLevelIcon={interactivityLevelIcon}
                durationLabel={durationLabel}
                durationIcon={durationIcon}
                industryLabel={industryLabel}
                industryIcon={industryIcon}
              />
            );
          case "ImageSliderModule":
            return <ImageSliderModule key={index} data={module} />;
          case "CoursesSliderModule":
            return <CoursesSliderModule key={index} data={module} />;
          case "CoursesGroupsSliderModule":
            return <CoursesSliderModule key={index} data={module} />;
          case "ImageWithHeadingSliderModule":
            return <ImageSliderModule key={index} data={module} />;
          case "CourseFeaturesModule":
            return <CourseFeaturesModule key={index} data={module} />;
          case "ContactFormModule":
            return <ContactFormModule key={index} data={module} />;
          case "PartnershipFormModule":
            return <ContactFormModule key={index} data={module} />;
          case "ContactsModule":
            return <ContactsModule key={index} data={module} />;
          case "PricingModule":
            return <PricingModule key={index} data={module} />;
          case "TopBlogsModule":
            return <TopBlogsModule key={index} data={module} />;
          case "ParagraphModule":
            return <ParagraphModule key={index} data={module} />;
          case "ImageModule":
            return <ImageModule key={index} data={module} />;
          case "ResourceModule":
            return <ResourceModule key={index} data={module} />;
          case "VideosModule":
            return <VideosModule key={index} data={module} />;
          case "FaqModule":
            return <FaqModule key={index} data={module} />;
          case "BlogItemsByCategoryModule":
            return <BlogItemsByCategoryModule key={index} data={module} />;
          case "ResourcesModule":
            return <BlogItemsByCategoryModule key={index} data={module} />;
          case "ResourceDownloadFormModule":
            return <ResourceDownloadFormModule key={index} data={module} />;
          case "ParagraphWithImageModule":
            return <ParagraphWithImageModule key={index} data={module} />;
          case "AnchorModule":
            return <AnchorModule key={index} data={module} />;
          case "TimesInfoModule":
            return <TimesInfoModule key={index} data={module} />;
          default:
            return null;
        }
      })()}
    </>
  );
};
export default ContentModule;
