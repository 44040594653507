import React, { useMemo, useState } from "react";
import { IContentModules } from "../../globalTypes";
import PricingInteractivityCard from "../../components/PricingInteractivityCard/PricingInteractivityCard";
import { getLink } from "../../components/functions/getLink";
import { useNavigate } from "react-router-dom";
import { customModuleStyle, lightButtonStyle } from "./CustomStyles";

interface IProps {
  data: IContentModules;
}
const PricingInteractivityModule = ({ data }: IProps) => {
  const navigate = useNavigate();
  const [levelSelected, setLevelSelected] = useState<string | undefined>(
    "Intermediate",
  );

  const levelSelectedData = useMemo(() => {
    if (levelSelected) {
      return data?.cards.find((card) => card.planName === levelSelected);
    }
  }, [levelSelected]);

  return (
    <div className={`${customModuleStyle(data?.withBackground)} px-10 xl:px-0`}>
      <div className="lg:mx-auto xl:w-[1230px]">
        <div className="mb-10">
          <h2 className="pb-[10px] font-roboto text-25 font-bold text-blue-dark xl:pb-5 xl:text-50">
            {data?.heading}
          </h2>
          <p className="pb-[10px] font-roboto text-lg font-bold leading-[26px] text-gray xl:pb-5 xl:text-[25px]">
            {data?.subHeading}
          </p>
          <p className="font-roboto text-base text-gray xl:text-xl">
            {data?.description}
          </p>
        </div>
        <div className="hidden grow flex-col flex-nowrap justify-between lg:flex lg:flex-row lg:flex-wrap">
          {data?.cards?.map((card, index) => (
            <PricingInteractivityCard data={card} key={index} />
          ))}
        </div>

        <div className="flex justify-center rounded-md lg:hidden" role="group">
          {data?.cards.map((card, index) => (
            <button
              key={index}
              type="button"
              onClick={() => setLevelSelected(card?.planName)}
              className={
                index === 0 && levelSelected === card?.planName
                  ? "rounded-l-3xl border border-gray-light bg-gray-light px-4 py-2 text-lg font-medium text-gray-dark"
                  : index === 0 && levelSelected !== card?.planName
                  ? "rounded-l-3xl border border-gray-light bg-white px-4 py-2 text-lg font-medium text-gray-dark"
                  : index === data?.cards?.length - 1 &&
                    levelSelected === card?.planName
                  ? "rounded-r-3xl border border-gray-light bg-gray-light px-4 py-2 text-lg font-medium text-gray-dark"
                  : index === data?.cards?.length - 1 &&
                    levelSelected !== card?.planName
                  ? "rounded-r-3xl border border-gray-light bg-white px-4 py-2 text-lg font-medium text-gray-dark"
                  : index !== data?.cards?.length - 1 &&
                    index !== 0 &&
                    levelSelected === card?.planName
                  ? "border border-gray-light bg-gray-light px-4 py-2 text-lg font-medium text-gray-dark"
                  : "border border-gray-light bg-white px-4 py-2 text-lg font-medium text-gray-dark"
              }
            >
              {card?.planName}
            </button>
          ))}
        </div>

        {levelSelectedData && (
          <div className="mx-auto mt-10 w-full md:w-1/2 lg:hidden">
            <PricingInteractivityCard data={levelSelectedData} />
          </div>
        )}

        {data?.link && (
          <button
            onClick={() => {
              const linkURL = getLink(data?.link?.url || "");
              navigate(linkURL);
            }}
            className={`${lightButtonStyle} mt-[30px] hidden px-6 py-2.5 lg:block`}
          >
            {data?.link.label}
          </button>
        )}
      </div>
    </div>
  );
};

export default PricingInteractivityModule;
