import React from "react";

const LinkedinSvg = () => {
    return (
        <svg width="70" height="72" viewBox="0 0 70 72" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M41.1137 30.2795C45.9803 30.2795 49.9247 34.2239 49.9247 39.0905V49.37H44.0507V39.0905C44.0507 37.4678 42.7364 36.1535 41.1137 36.1535C39.491 36.1535 38.1767 37.4678 38.1767 39.0905V49.37H32.3027V39.0905C32.3027 34.2239 36.2471 30.2795 41.1137 30.2795Z"
                fill="#0A0F3C"/>
            <rect x="20.5547" y="31.7483" width="5.874" height="17.622" fill="#0A0F3C"/>
            <circle cx="23.4917" cy="24.406" r="2.937" fill="#0A0F3C"/>
        </svg>
    );
};

export default LinkedinSvg;


