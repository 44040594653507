import React from "react";

const Background3Image = () => {
  return (
    <svg
      className="absolute -right-16 bottom-0 w-1/2 md:-right-40 lg:right-0 lg:w-[400px] 2xl:w-[22%]"
      width="605"
      height="607"
      viewBox="0 0 705 747"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="542.967"
        cy="542.133"
        rx="542.967"
        ry="542.133"
        transform="matrix(-0.848881 0.528584 -0.531255 -0.847212 1497.85 918.603)"
        stroke="#DDE0FF"
        strokeWidth="2"
      />
      <path
        d="M1005.98 1187.52C762.631 1339.34 441.615 1264.84 290.396 1021.18C138.893 778.827 213.544 459.526 456.673 308.134C699.802 156.742 1020.45 231.848 1172.37 474.126C1324.29 716.403 1249.02 1036.02 1005.98 1187.52Z"
        stroke="#DDE0FF"
        strokeWidth="1.926"
      />
      <path
        d="M974.765 1169.04C742.849 1314.03 437.214 1241.74 293.185 1008.8C150.172 777.497 221.205 473.806 452.683 329.668C684.162 185.531 989.853 258.536 1134.5 489.203C1279.14 719.871 1206.07 1024.68 974.765 1169.04Z"
        stroke="#DDE0FF"
        strokeWidth="1.851"
      />
      <path
        d="M943.553 1150.55C723.069 1288.72 432.416 1218.9 295.973 996.416C161.05 776.416 228.867 488.082 448.693 351.2C668.521 214.317 959.259 285.223 1096.62 504.28C1233.99 723.339 1163.11 1013.34 943.553 1150.55Z"
        stroke="#DDE0FF"
        strokeWidth="1.777"
      />
      <path
        d="M912.342 1132.06C703.289 1263.41 427.62 1196.06 298.761 984.034C171.926 775.341 236.529 502.361 444.705 372.734C652.882 243.105 928.666 311.909 1058.75 519.357C1188.83 726.806 1120.16 1002 912.342 1132.06Z"
        stroke="#DDE0FF"
        strokeWidth="1.702"
      />
      <path
        d="M881.13 1113.58C683.509 1238.1 422.825 1173.22 301.548 971.653C182.795 774.273 244.189 516.64 440.715 394.267C637.241 271.894 898.072 338.596 1020.88 534.435C1143.68 730.272 1077.21 990.657 881.13 1113.58Z"
        stroke="#DDE0FF"
        strokeWidth="1.628"
      />
      <path
        d="M849.919 1095.09C663.729 1212.79 418.031 1150.4 304.339 959.274C193.659 773.215 251.852 530.921 436.727 415.803C621.603 300.683 867.48 365.284 983.002 549.512C1098.53 733.741 1034.26 979.317 849.919 1095.09Z"
        stroke="#DDE0FF"
        strokeWidth="1.553"
      />
      <path
        d="M818.708 1076.6C643.95 1187.47 413.239 1127.58 307.128 946.892C204.519 772.166 259.514 545.2 432.74 437.335C605.964 329.472 836.885 391.97 945.129 564.59C1053.37 737.21 991.312 967.977 818.708 1076.6Z"
        stroke="#DDE0FF"
        strokeWidth="1.479"
      />
      <path
        d="M787.497 1058.12C623.94 1161.8 408.448 1104.77 309.916 934.51C215.367 771.127 267.175 559.477 428.75 458.867C590.324 358.258 806.293 418.656 907.256 579.666C1008.22 740.676 948.135 956.282 787.497 1058.12Z"
        stroke="#DDE0FF"
        strokeWidth="1.404"
      />
      <path
        d="M756.284 1039.63C604.13 1136.45 403.656 1081.98 312.704 922.129C226.205 770.103 274.836 573.757 424.76 480.401C574.685 387.046 775.699 445.344 869.382 594.743C963.064 744.141 905.156 944.9 756.284 1039.63Z"
        stroke="#DDE0FF"
        strokeWidth="1.33"
      />
      <path
        d="M725.073 1021.14C584.322 1111.1 398.867 1059.2 315.493 909.748C237.028 769.096 282.497 588.036 420.771 501.935C559.045 415.834 745.105 472.03 831.509 609.82C917.912 747.61 862.18 933.521 725.073 1021.14Z"
        stroke="#DDE0FF"
        strokeWidth="1.255"
      />
      <path
        d="M693.862 1002.66C564.516 1085.74 394.079 1036.44 318.283 897.366C247.837 768.106 290.16 602.314 416.783 523.468C543.406 444.621 714.512 498.717 793.635 624.897C872.758 751.077 819.204 922.141 693.862 1002.66Z"
        stroke="#DDE0FF"
        strokeWidth="1.181"
      />
      <path
        d="M662.649 984.171C544.708 1060.39 389.288 1013.71 321.069 884.985C258.62 767.149 297.82 616.594 412.792 545.002C527.765 473.41 683.918 525.404 755.761 639.975C827.604 754.545 776.229 910.768 662.649 984.171Z"
        stroke="#DDE0FF"
        strokeWidth="1.106"
      />
      <path
        d="M631.438 965.685C524.901 1035.04 384.499 991.01 323.858 872.605C269.376 766.222 305.481 630.872 408.803 566.535C512.126 502.198 653.326 552.092 717.889 655.053C782.451 758.012 733.256 899.395 631.438 965.685Z"
        stroke="#DDE0FF"
        strokeWidth="1.032"
      />
      <path
        d="M600.226 947.198C505.095 1009.7 379.704 968.353 326.648 860.224C280.089 765.339 313.143 645.151 404.814 588.069C496.486 530.986 622.732 578.777 680.015 670.128C737.298 761.479 690.284 888.029 600.226 947.198Z"
        stroke="#DDE0FF"
        strokeWidth="0.957"
      />
      <path
        d="M569.014 928.712C485.291 984.36 374.899 945.753 329.435 847.843C290.743 764.514 320.804 659.431 400.825 609.603C480.846 559.775 592.139 605.465 642.141 685.206C692.144 764.947 647.315 876.668 569.014 928.712Z"
        stroke="#DDE0FF"
        strokeWidth="0.883"
      />
      <path
        d="M537.803 910.225C465.489 959.026 370.071 923.233 332.223 835.461C301.311 763.768 328.465 673.709 396.836 631.136C465.207 588.563 561.545 632.152 604.267 700.283C646.99 768.415 604.351 865.315 537.803 910.225Z"
        stroke="#DDE0FF"
        strokeWidth="0.809"
      />
      <path
        d="M506.591 891.737C445.691 933.702 365.193 900.829 335.014 823.079C311.742 763.13 336.127 687.987 392.847 652.668C449.567 617.349 530.951 658.836 566.394 715.359C601.837 771.881 561.394 853.974 506.591 891.737Z"
        stroke="#DDE0FF"
        strokeWidth="0.734"
      />
      <path
        d="M475.379 873.252C425.9 908.398 360.193 878.601 337.801 810.698C321.954 762.651 343.787 702.267 388.857 674.203C433.927 646.139 500.357 685.524 528.52 730.436C556.682 775.348 518.448 842.659 475.379 873.252Z"
        stroke="#DDE0FF"
        strokeWidth="0.66"
      />
      <path
        d="M444.169 854.766C406.129 883.13 354.882 856.635 340.59 798.318C331.783 762.381 351.449 716.545 384.868 695.736C418.289 674.926 469.765 712.212 490.648 745.514C511.531 778.816 475.526 831.383 444.169 854.766Z"
        stroke="#DDE0FF"
        strokeWidth="0.585"
      />
      <path
        d="M412.956 836.278C386.415 857.95 348.551 835.005 343.378 785.935C340.891 762.34 359.11 730.824 380.88 717.268C402.649 703.713 439.171 738.898 452.773 760.591C466.376 782.283 432.66 820.19 412.956 836.278Z"
        stroke="#DDE0FF"
        strokeWidth="0.511"
      />
      <path
        d="M381.746 817.793C367.029 833.109 337.686 812.665 346.168 773.555C348.688 761.934 366.773 745.103 376.892 738.802C387.01 732.502 408.578 765.585 414.9 775.668C421.224 785.751 389.996 809.205 381.746 817.793Z"
        stroke="#DDE0FF"
        strokeWidth="0.436"
      />
    </svg>
  );
};

export default Background3Image;
