import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import AppRoutesWrapper from "./routes/AppRoutesWrapper";
import { BrowserRouter } from "react-router-dom";
import 'swiper/css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Initialze the client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppRoutesWrapper />
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
