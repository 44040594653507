import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { IBlogList } from "../../globalTypes";
import Clock from "../svg/Clock";
import { getLink } from "../functions/getLink";
import { useMediaQuery } from "usehooks-ts";
import {
  blogCardHeading,
  blogCardImage,
  blogCardLabel,
  blogCardLink,
  blogCardStyle,
  blogCardSubmoduleStyle1,
  blogCardSubmoduleStyle2,
} from "../../pages/Modules/CustomStyles";

interface IProps {
  data: IBlogList;
  isImgOnRight?: boolean;
  moduleName?: string;
  customStyle?: string;
}
const env = window._env_ ?? {}

const BlogCard = ({ data, isImgOnRight, moduleName, customStyle }: IProps) => {
  const baseUrl = env.REACT_APP_IMG_BASE_URL;
  const isSmallScreen = useMediaQuery("(max-width: 640px)");

  const linkURL = useMemo(() => {
    if (data?.absoluteUrl) {
      return getLink(data?.absoluteUrl || "");
    } else {
      return "/";
    }
  }, [data]);

  return (
    <div className={blogCardStyle(customStyle, moduleName)}>
      <Link
        to={linkURL}
        className={blogCardLink(isImgOnRight)}
        target={moduleName === "ResourcesModule" ? "_blank" : "_self"}
      >
        <div className={blogCardSubmoduleStyle1(isImgOnRight)}>
          {data?.thumbnail?.url ? (
            <img
              src={baseUrl + data?.thumbnail.url}
              alt={data?.hero?.image?.altText}
              className={blogCardImage(
                isImgOnRight,
                isSmallScreen,
                !!data?.thumbnail?.url,
              )}
            />
          ) : (
            <div
              className={blogCardImage(
                isImgOnRight,
                isSmallScreen,
                !!data?.thumbnail?.url,
              )}
            >
              {" "}
            </div>
          )}
        </div>

        <div className={blogCardSubmoduleStyle2(isImgOnRight)}>
          <div className="flex h-auto w-full flex-col items-start justify-between text-xl font-bold">
            <span className={blogCardLabel}>
              {data?.category?.label || data?.subHeading}
            </span>
            <span className={blogCardHeading(isImgOnRight)}>
              {data?.hero?.heading || data?.heading}
            </span>
          </div>

          {!isImgOnRight && (
            <div className="textEllipsis2Lines mb-[10px] text-gray">
              {data?.summary}{" "}
            </div>
          )}
          {isImgOnRight && data.readingTime && (
            <div className="pb-[15px]">
              <div className="flex items-center justify-start text-gray-secondary">
                <Clock />
                <span>
                  {data?.readingTime} {data?.readingTimeUnit}
                </span>
              </div>
            </div>
          )}
        </div>

        {!isImgOnRight && data.readingTime && (
          <div className="mt-auto pb-[15px]">
            <div className="flex items-center justify-start text-gray-border">
              <Clock />
              <span>
                {data?.readingTime} {data?.readingTimeUnit}
              </span>
            </div>
          </div>
        )}
      </Link>
    </div>
  );
};

export default BlogCard;
