import React, { ReactNode, useMemo } from "react";
import { Link as NavLink } from "react-router-dom";
import ReadMore from "../svg/ReadMore";
import { getLink } from "../functions/getLink";

interface IProps {
  linkUrl: string;
  target?: string | null;
  label?: string | ReactNode;
  customStyle: string;
  customLabelStyle?: string;
  shouldShowArrow?: boolean;
  isExternal?: boolean;
}

const LinkComp = ({
  linkUrl,
  target,
  label,
  customStyle,
  customLabelStyle,
  shouldShowArrow,
  isExternal,
}: IProps) => {
  const linkURL: string = useMemo(() => {
    if (!isExternal && linkUrl) {
      return getLink(linkUrl || "");
    } else if (isExternal) {
      return linkUrl;
    } else {
      return "/";
    }
  }, [linkUrl]);

  return (
    <NavLink
      to={linkURL}
      target={target || undefined}
      className={`${customStyle}`}
    >
      <span className={customLabelStyle}>{label}</span>
      {/* <span className="read-more-blue-hover">{label}</span> */}
      {shouldShowArrow && <ReadMore />}
    </NavLink>
  );
};

export default LinkComp;
