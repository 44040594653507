import React from "react";

const YoutubeSvg = () => {
    return (
        <svg width="70" height="72" viewBox="0 0 70 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M49.2632 28.3323C48.9402 27.0429 47.9519 26.0238 46.6728 25.6625C44.3775 25.1016 35.1921 25.1016 35.1921 25.1016C35.1921 25.1016 26.0081 25.1016 23.7128 25.7154C22.4337 26.0781 21.4454 27.0958 21.1224 28.3851C20.7024 30.7156 20.4968 33.0799 20.5085 35.4471C20.4939 37.8335 20.6995 40.2154 21.1224 42.562C21.4778 43.8117 22.4602 44.7824 23.7128 45.126C26.0081 45.7399 35.1921 45.7399 35.1921 45.7399C35.1921 45.7399 44.3775 45.7399 46.6728 45.126C47.9519 44.7633 48.9402 43.7456 49.2632 42.4563C49.6803 40.1434 49.8859 37.7967 49.8771 35.4471C49.8918 33.0623 49.6862 30.6804 49.2632 28.3323Z" fill="#0A0F3C"/>
            <path d="M32.1895 39.8142L39.8653 35.4484L32.1895 31.084V39.8142Z" fill="white"/>
        </svg>

    );
};

export default YoutubeSvg;




