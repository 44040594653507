import React, { useState } from "react";
import { useQuery } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import ContentPage from "./ContentPage";
import { getContent } from "./contentApi";
import { IContentPage } from "../../globalTypes";
import { useNavigate } from "react-router-dom";

interface IProps {
  url: string;
  pageTemplate: string;
}
const ContentWrapper = ({ url, pageTemplate }: IProps) => {
  const [pageData, setPageData] = useState<IContentPage | null>(null);
  const [err, setErr] = useState<string>("");
  const navigate = useNavigate();

  const { isLoading, isError } = useQuery<
    AxiosResponse<IContentPage>,
    AxiosError
  >(["query-content", url], async () => await getContent(url), {
    onSuccess: (res) => {
      setPageData(res.data);
      setErr("");
    },
    onError: (err) => {
      if (err?.response?.status === 404) {
        navigate("*");
      }
      setErr(err.message);
    },
  });

  if (isLoading) {
    return <div></div>;
  }

  if (isError) {
    return <div>{err}</div>;
  }

  return <ContentPage data={pageData} pageTemplate={pageTemplate} />;
};

export default ContentWrapper;
