import React, { useState } from "react";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { IApiResponse, IContentModules, IFormValues } from "../../globalTypes";
import InputField from "../../components/InputField/InputField";
import { submitForm } from "../ContentPage/contentApi";
import TextArea from "../../components/TextArea/TextArea";
import ReCAPTCHAComp from "../../components/ReCAPTCHA/ReCAPTCHAComp";
import AgreeToTermsCheckbox from "../../components/AgreeToTermsCheckbox/AgreeToTermsCheckbox";
import { customModuleStyle } from "./CustomStyles";
import ResponceFormData from "../../components/ResponceForm/ResponceFormData";

interface IProps {
  data: IContentModules;
}

const validationSchema = Yup.object({
  Email: Yup.string().email("Not a valid email").required("Required"),
  FirstName: Yup.string().required("Required"),
  LastName: Yup.string().required("Required"),
  Description: Yup.string(),
  isVerified: Yup.boolean().oneOf([true], "Required"),
  isAgreedToTerms: Yup.boolean().oneOf([true], "Required"),
});

const ContactFormModule = ({ data }: IProps) => {
  const initialValues = {
    FirstName: "",
    LastName: "",
    Email: "",
    Description: "",
    isVerified: false,
    isAgreedToTerms: false,
  };
  const [respData, setRespData] = useState<IApiResponse | null>(null);

  const handleSubmit = async (
    values: IFormValues,
    actions: FormikHelpers<IFormValues>,
  ) => {
    actions.setSubmitting(true);

    const { FirstName, LastName, Email, Description } = values;

    const formData = new FormData();
    formData.append("FirstName", FirstName);
    formData.append("LastName", LastName);
    formData.append("Email", Email);
    Description && formData.append("Description", Description);

    try {
      const status = await submitForm(formData, data?.apiUrl);
      if (status?.data) {
        setRespData(status.data);
        actions.resetForm();
        actions.setSubmitting(false);
      }
    } catch (error) {
      actions.resetForm();
      actions.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div
      className={`${customModuleStyle(data?.withBackground)} px-[30px] xl:px-0`}
    >
      <div className="flex flex-col items-start justify-between lg:mx-auto lg:flex-row xl:w-container">
        <div className="w-full lg:w-[47%] lg:pr-6">
          <h2 className="pb-4 font-roboto text-25 font-bold text-blue-dark xl:text-40">
            {data?.heading}
          </h2>
          <div className="dangerouslySetInnerHTML pb-[30px] text-25 text-gray lg:pb-0 xl:text-40">
            <div
              dangerouslySetInnerHTML={
                { __html: data?.description || "" } as { __html: string }
              }
            />
          </div>
        </div>
        <div className="h-auto w-full rounded-[30px] border border-gray-light px-[16px] py-[30px] lg:mx-auto lg:w-[64%] lg:p-[50px]">
          {!respData && (
            <>
              <h2 className="pb-[20px] font-roboto text-25 font-bold text-blue-dark xl:pb-[40px] xl:text-40">
                {data?.formHeading}
              </h2>
              <form onSubmit={formik.handleSubmit}>
                <InputField
                  id="FirstName"
                  name="FirstName"
                  value={formik.values.FirstName}
                  isRequired={true}
                  placeholder={data?.firstNamePlaceholder || ""}
                  label={data?.firstNameLabel || ""}
                  type="text"
                  hasError={
                    formik.touched.FirstName && Boolean(formik.errors.FirstName)
                  }
                  errMsg={
                    formik.touched.FirstName && formik.errors.FirstName
                      ? formik.errors.FirstName
                      : ""
                  }
                  onChange={(e) => formik.handleChange(e)}
                />

                <InputField
                  id="LastName"
                  name="LastName"
                  value={formik.values.LastName}
                  isRequired={true}
                  placeholder={data?.lastNamePlaceholder || ""}
                  label={data?.lastNameLabel || ""}
                  type="text"
                  hasError={
                    formik.touched.LastName && Boolean(formik.errors.LastName)
                  }
                  errMsg={
                    formik.touched.LastName && formik.errors.LastName
                      ? formik.errors.LastName
                      : ""
                  }
                  onChange={(e) => formik.handleChange(e)}
                />

                <InputField
                  id="Email"
                  name="Email"
                  value={formik.values.Email}
                  isRequired={true}
                  placeholder={data?.emailPlaceholder || ""}
                  label={data?.emailLabel || ""}
                  type="email"
                  hasError={
                    formik.touched.Email && Boolean(formik.errors.Email)
                  }
                  errMsg={
                    formik.touched.Email && formik.errors.Email
                      ? formik.errors.Email
                      : ""
                  }
                  onChange={(e) => formik.handleChange(e)}
                />

                <TextArea
                  id="description"
                  name="description"
                  value={formik.values.Description}
                  placeholder={data?.descriptionPlaceholder || ""}
                  label={data?.descriptionLabel || ""}
                  onChange={(e) =>
                    formik.setFieldValue("Description", e.target.value)
                  }
                />

                <AgreeToTermsCheckbox
                  isChecked={formik.values.isAgreedToTerms}
                  onChange={(e) =>
                    formik.setFieldValue("isAgreedToTerms", e.target.checked)
                  }
                  label={data?.agreeToTermsText || ""}
                  hasError={
                    formik.touched.isAgreedToTerms &&
                    Boolean(formik.errors.isAgreedToTerms)
                  }
                  errMsg={
                    formik.touched.isAgreedToTerms &&
                      formik.errors.isAgreedToTerms
                      ? formik.errors.isAgreedToTerms
                      : ""
                  }
                />

                <ReCAPTCHAComp
                  label={data?.reCaptchaLabel || ""}
                  onChange={() => formik.setFieldValue("isVerified", true)}
                  hasError={
                    formik.touched.isVerified &&
                    Boolean(formik.errors.isVerified)
                  }
                  errMsg={
                    formik.touched.isVerified && formik.errors.isVerified
                      ? formik.errors.isVerified
                      : ""
                  }
                />

                <button
                  type="submit"
                  className="flex w-full items-center justify-center whitespace-nowrap rounded-sm border border-blue bg-blue p-[15px] text-white hover:border-blue-hover hover:bg-blue-hover"
                >
                  {data?.buttonText}
                </button>
              </form>
            </>
          )}

          {respData && (
            <ResponceFormData respData={respData} setRespData={setRespData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactFormModule;
