import React from "react";
import Card from "../../components/Card/Card";
import { FeatureBlocks, IContentModules } from "../../globalTypes";
import {
  customModuleStyle,
  topFeatureModuleCardWrapper,
  topFeatureModuleCustomWrapper,
  topFeatureModuleHeadingStyle,
} from "./CustomStyles";
interface IProp {
  data: IContentModules;
  pageTemplate?: string;
}
const env = window._env_ ?? {}

const TopFeaturesModule = ({ data, pageTemplate }: IProp) => {
  const baseUrl = env.REACT_APP_IMG_BASE_URL;
  return (
    <div className={customModuleStyle(data?.withBackground)}>
      <div className={topFeatureModuleCustomWrapper(pageTemplate)}>
        <div className={topFeatureModuleHeadingStyle(pageTemplate)}>
          {data.heading}
        </div>
        <div className={topFeatureModuleCardWrapper(pageTemplate)}>
          {data?.featureBlocks?.map((item: FeatureBlocks, index) => (
            <Card
              key={index}
              customClassNames="bg-white p-[30px] rounded-[40px]"
              customClassNameParagraph="lg:text-lg text-base"
              image={baseUrl + item?.icon?.url}
              title={item.heading}
              paragraph={item.description}
              link={item.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopFeaturesModule;
