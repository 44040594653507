import React, { Dispatch, SetStateAction } from "react";
import { ICard, IContentModules } from "../../../globalTypes";

interface IProps {
  data: IContentModules | null;
  planSelected?: ICard | null;
  setFormOpen: Dispatch<SetStateAction<boolean>>;
  setGetStartedOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const GetStartedContext = ({
  data,
  planSelected,
  setFormOpen,
  setGetStartedOpen,
}: IProps) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <h2 className="px-[20px] pb-2 text-center text-20/26 font-bold text-gray-dark xl:text-3xl">
        {data?.ctaBoxHeading}
      </h2>
      <div className="px-[20px] pb-5 text-center text-lg text-gray-secondary xl:pb-[30px]">
        {data?.ctaBoxDescription}
      </div>

      <div className="flex flex-wrap items-center justify-center rounded-b-md px-[20px] pb-5 xl:pb-[30px]">
        <a href={planSelected?.link?.url || ""} target="_blank">
          <button
            type="submit"
            className="flex w-full items-center justify-center whitespace-nowrap rounded-sm border border-blue bg-blue p-[15px] text-white hover:border-blue-hover hover:bg-blue-hover"
          >
            <span className="h-5">{planSelected?.link?.label}</span>
          </button>
        </a>
      </div>

      <div className="pb-[30px] text-sm text-gray-lighter md:flex md:items-center md:justify-start lg:px-[40px]">
        {data?.ctaBoxText}
        <button
          type="submit"
          className="text-blue underline hover:text-blue-hover md:ml-2"
          onClick={() => {
            setFormOpen(true);
            setGetStartedOpen(false);
          }}
        >
          {data?.formLinkLabel}
        </button>
      </div>
    </div>
  );
};

export default GetStartedContext;
