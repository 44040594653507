import { useMemo } from "react";
import { IContentModules, IImage } from "../../globalTypes";
import useMediaQuery from "../hooks/useMediaQuery";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { verticalCarouselStyle } from "../../pages/Modules/CustomStyles";

interface IProps {
  data: IContentModules;
}
const env = window._env_ ?? {}

const VerticalCarousel = ({ data }: IProps) => {
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const isBigScreen = useMediaQuery("(min-width: 1024px)");
  const loadingTimes = [9000, 6000, 5000, 8000, 7000];

  const bigScreenElements = 5;
  const tabletElements = 4;
  const smallScreenElements = 3;
  const baseUrl = env.REACT_APP_IMG_BASE_URL;

  // helper function that allocates element into 3 or 5 arrays, dependent on screen size
  const allocateElements = (
    oldDataArr: [] | IImage[],
    newArray: Array<IImage[]>,
    carouselElements: number,
  ) => {
    let pointer = 0;

    while (pointer < oldDataArr.length) {
      for (let i = 0; i < carouselElements; i++) {
        const element: IImage = oldDataArr[pointer];
        oldDataArr && oldDataArr[pointer] && newArray[i].push(element);
        pointer++;
      }
    }
    return newArray;
  };

  // returns the icons, allocated in 3 or 5 arrays (dependent on the screen size)
  const displayData = useMemo(() => {
    if (data?.icons) {
      const newArray: Array<[] | IImage[]> = [[], [], [], [], []];
      // eslint-disable-next-line no-unsafe-optional-chaining
      const oldDataArr = data?.icons ? [...data?.icons] : [];
      if (isBigScreen) {
        allocateElements(oldDataArr, newArray, bigScreenElements);
      }
      if (isSmallScreen) {
        allocateElements(oldDataArr, newArray, smallScreenElements);
      }
      if (!isBigScreen && !isSmallScreen) {
        allocateElements(oldDataArr, newArray, tabletElements);
      }
      return newArray;
    }
  }, [isBigScreen, isSmallScreen]);

  function isImageArray(element: IImage[]): element is IImage[] {
    return element && element.length > 0 ? true : false;
  }

  return (
    <div className={verticalCarouselStyle(isBigScreen, isSmallScreen)}>
      {
        displayData
          ?.filter((element: IImage[]) => isImageArray(element))
          .map((group: IImage[], index: number) => {
            return (
              group.length > 0 && (
                <Carousel
                  axis="vertical"
                  showArrows={false}
                  showThumbs={false}
                  showStatus={false}
                  showIndicators={false}
                  stopOnHover={false}
                  useKeyboardArrows={false}
                  autoFocus={true}
                  autoPlay={true}
                  interval={loadingTimes[index]}
                  transitionTime={1000}
                  verticalSwipe={"natural"}
                  infiniteLoop={true}
                  swipeable={false}
                  key={index}
                >
                  {group &&
                    group.map((element: IImage, index2: number) => (
                      <img
                        src={(baseUrl||'') + element?.url}
                        alt={element?.altText}
                        key={index2}
                      />
                    ))}
                </Carousel>
              )
            );
          })}
    </div>
  );
};

export default VerticalCarousel;
