import { useEffect, useState } from "react";
import { IContentModules, IECourse, IECourses } from "../../globalTypes";
import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { getNextPageData } from "../ContentPage/contentApi";
import CustomerStoriesCard from "../../components/CustomerStoriesCard/CustomerStoriesCard";
import { customModuleStyle, gridCardsOrderStyle } from "./CustomStyles";

interface IProps {
  data: IContentModules;
}

const CustomerStoriesModule = ({ data }: IProps) => {
  const [nextPage, setNextPage] = useState<number>(1);
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);
  const [shouldUpdateLink, setShouldUpdateLink] = useState<boolean>(false);
  const [newLink, setNewLink] = useState<string>("");
  const [customerStories, setCustomerStories] = useState<IECourse[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);

  useEffect(() => {
    if (data?.customerStories) {
      setCustomerStories(data?.customerStories?.results);
      setHasNextPage(!!data?.customerStories?.hasNextPage);
    }
  }, [data]);

  useEffect(() => {
    let nextPageLink = "";
    if (shouldUpdateLink) {
      if (data?.customerStories?.filter?.currentPageId) {
        nextPageLink = `${nextPage}&currentPageId=${data?.customerStories?.filter?.currentPageId}`;
      }
      if (data?.videos?.filter?.moduleKey) {
        nextPageLink = `${nextPage}&currentPageId=${data?.customerStories?.filter?.moduleKey}`;
      }
      if (data?.apiUrl) {
        nextPageLink = data?.apiUrl.replace("page=2", `page=${nextPage}`);
      }
      setNewLink(nextPageLink);
      setShouldFetch(true);
    }
  }, [data, nextPage, shouldUpdateLink]);

  useQuery<AxiosResponse<IECourses>, Error>(
    ["query-next-page", shouldFetch],
    async () => await getNextPageData(newLink),
    {
      enabled: shouldFetch,
      onSuccess: (res) => {
        if (nextPage === 1) {
          setCustomerStories(res.data.results);
          setShouldFetch(false);
          setHasNextPage(res.data?.hasNextPage || false);
        } else {
          const newData = [...customerStories, ...res.data.results];
          setCustomerStories(newData);
          setShouldFetch(false);
          setHasNextPage(res.data?.hasNextPage || false);
        }
      },
    },
  );

  return (
    <div className={customModuleStyle(data?.withBackground)}>
      <div className="px-10 xl:mx-auto xl:w-container xl:p-0">
        <div className="pb-[30px] text-25/35 font-bold text-blue-dark xl:pb-[50px] xl:text-40 ">
          {data?.heading}
        </div>
        <div className={gridCardsOrderStyle}>
          {customerStories?.map((item: IECourse, index: number) => (
            <CustomerStoriesCard
              key={index}
              data={item}
              linkLabel={data?.linkLabel}
            />
          ))}
        </div>
      </div>

      {hasNextPage && (
        <div className="flex justify-center">
          <button
            type="button"
            className="mt-[30px] rounded-[2px] bg-blue px-10 py-4 text-white"
            onClick={() => {
              setNextPage((current) => current + 1);
              setShouldUpdateLink(true);
            }}
          >
            {data?.buttonText}
          </button>
        </div>
      )}
    </div>
  );
};

export default CustomerStoriesModule;
