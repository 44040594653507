import React from "react";
import { IContentModules } from "../../globalTypes";
import LinkComp from "../../components/LinkComp/LinkComp";
import { customModuleStyle } from "./CustomStyles";

interface IProps {
  data: IContentModules;
}
const ExploreMoreModule = ({ data }: IProps) => {
  return (
    <div className={`${customModuleStyle(data?.withBackground)} px-10 xl:px-0`}>
      <div className="lg:mx-auto xl:w-[1230px]">
        <h2 className="pb-4 font-roboto text-2xl font-bold text-blue-dark xl:text-3xl">
          {data?.heading}
        </h2>
        <div className="wrap flex w-full flex-col justify-between lg:flex-row">
          {data?.cards?.map((card, index) => (
            <div key={index} className="w-full lg:w-[30%]">
              <div className="py-4 text-xl font-bold text-blue-dark lg:text-2xl">
                {card?.heading}
              </div>
              <div className="mt-auto">
                {card?.link ? (
                  <div key={card?.link.label} className="w-[120px]">
                    <LinkComp
                      linkUrl={card?.link?.url || ""}
                      target={card?.link?.target}
                      label={card?.link?.label}
                      shouldShowArrow={true}
                      customStyle="read-more-btn flex items-center justify-start text-base font-medium text-blue"
                      customLabelStyle="read-more-blue-hover"
                      isExternal={card?.link?.isExternal}
                    />
                  </div>
                ) : (
                  <div className="h-9"></div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExploreMoreModule;
