import React, { useEffect, useState } from "react";
import { IContentModules, IFaq } from "../../globalTypes";
import ReadMore from "../../components/svg/ReadMore";
import { customModuleStyle } from "./CustomStyles";

interface IProps {
  data: IContentModules;
}

interface IsOpenObj extends IFaq {
  index: number;
  isOpen: boolean;
}
const FaqModule = ({ data }: IProps) => {
  const [items, setItems] = useState<IsOpenObj[] | undefined>();

  useEffect(() => {
    if (data?.items && data?.items?.length > 0) {
      const sectionClosed = data?.items.map((item, index) => ({
        ...item,
        index,
        isOpen: false,
      }));

      setItems(sectionClosed);
    }
  }, [data]);

  return (
    <div className={customModuleStyle(data?.withBackground)}>
      <div className="mx-4 px-10 lg:mx-auto xl:w-container xl:px-0">
        <div className="pb-[30px] text-xl font-bold xl:pb-[50px] xl:text-40 ">
          {data?.heading}
        </div>
        <div className="mb-4 h-auto w-full lg:mx-auto">
          {items?.map((item, index) => {
            return (
              <div key={index} className="w-full">
                <div
                  className="mb-5 flex cursor-pointer items-center text-base font-bold text-blue hover:text-blue-hover xl:text-xl"
                  onClick={() => {
                    const newItem = { ...item, isOpen: !item.isOpen };
                    const oldItems = [...items];
                    oldItems.splice(index, 1, newItem);
                    setItems(oldItems);
                  }}
                >
                  {item.question}
                  <div
                    className={
                      item.isOpen ? "ml-2 -rotate-90" : "ml-2 rotate-90"
                    }
                  >
                    <ReadMore />
                  </div>
                </div>
                {item?.isOpen && (
                  <div
                    className="dangerouslySetInnerHTML w-full pb-[20px] text-gray"
                    key={index}
                  >
                    <div
                      key={index}
                      dangerouslySetInnerHTML={
                        { __html: item?.answer || "" } as {
                          __html: string;
                        }
                      }
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FaqModule;
