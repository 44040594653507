import { IContentModules } from "../../globalTypes";
import { useMediaQuery } from "usehooks-ts";
import VerticalCarousel from "../../components/VerticalCarousel/VerticalCarousel";
import { customModuleStyle } from "./CustomStyles";

interface IProps {
  data: IContentModules;
}
const env = window._env_ ?? {}

const LogoShowcaseModule = ({ data }: IProps) => {
  const baseUrl = env.REACT_APP_IMG_BASE_URL;
  const isBigScreen = useMediaQuery("(min-width: 1024px)");

  return (
    <div className={`${customModuleStyle(data?.withBackground)} px-10 xl:px-0`}>
      <div className="lg:mx-auto xl:w-container">
        <h2 className="font-roboto text-25/35 font-bold text-blue-dark lg:mb-[100px] xl:text-40 ">
          {data?.heading}
        </h2>
        <div className="h-auto w-full lg:mx-auto">
          {isBigScreen && data?.desktopImage?.url && (
            <img
              src={baseUrl + data?.desktopImage?.url}
              alt={data?.desktopImage?.altText}
            />
          )}
          {!isBigScreen && <VerticalCarousel data={data} />}
        </div>
      </div>
    </div>
  );
};

export default LogoShowcaseModule;
