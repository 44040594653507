interface IProps {
  heading: string;
  description: string;
}
const CustomerStoriesDescriptionCard = ({ heading, description }: IProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex h-auto flex-col text-xl">
        <div className="pb-[10px] text-25 font-bold text-blue-dark xl:pb-[20px] xl:text-40 ">
          {heading}
        </div>

        <div
          className="dangerouslySetInnerHTML text-base text-gray-secondary"
          dangerouslySetInnerHTML={
            { __html: description || "" } as {
              __html: string;
            }
          }
        />
      </div>
    </div>
  );
};

export default CustomerStoriesDescriptionCard;
