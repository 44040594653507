import React from "react";
import { IContentModules } from "../../globalTypes";
import BlogCard from "../../components/BlogCard/BlogCard";
import {
  topBlogsModuleCardLeft,
  topBlogsModuleCardRight,
  topBlogsModuleCardWrapper,
  topBlogsModuleModuleStyle,
} from "./CustomStyles";

interface IProps {
  data: IContentModules;
}
const TopBlogsModule = ({ data }: IProps) => {
  return (
    <div className={topBlogsModuleModuleStyle}>
      <div className={topBlogsModuleCardWrapper}>
        {data?.blogsList && data?.blogsList[0] && (
          <div className={topBlogsModuleCardLeft(data?.blogsList)}>
            <div className="pb-[10px] font-roboto text-2xl font-extrabold text-blue-dark xl:pb-[50px] xl:text-50">
              {data?.heading}
            </div>
            <BlogCard data={data?.blogsList[0]} moduleName={data?.moduleName} />
          </div>
        )}
        {data?.blogsList && data?.blogsList?.length > 1 && (
          <div className={topBlogsModuleCardRight}>
            <div className="mx-10 pt-[30px] text-xl font-bold text-blue-dark xl:pb-[20px] xl:pt-[100px] xl:text-40">
              {data?.sideHeading}
            </div>
            {data?.blogsList?.map((blog, index) => {
              if (index === 0) {
                return;
              } else {
                return (
                  <div
                    key={index}
                    className="mx-10 border-b border-gray-light last:border-none"
                  >
                    <BlogCard
                      data={blog}
                      isImgOnRight={true}
                      moduleName={data?.moduleName}
                    />
                  </div>
                );
              }
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopBlogsModule;
