import React from "react";
import { IContentModules } from "../../globalTypes";

interface IProps {
  data: IContentModules;
}

const CourseFeaturesModule = ({ data }: IProps) => {
  return (
    <div className="w-full bg-blue-light py-[40px] lg:py-[50px]">
      <div className="px-10 lg:mx-auto xl:w-container xl:p-0">
        <div className="xl:text-40 text-25 pb-[10px] font-bold md:text-blue-dark xl:pb-[50px] ">
          {data?.heading}
        </div>
        <div className="grid lg:grid-cols-3 xs:grid-cols-1 gap-x-[30px]">
          {data?.columns?.map((column, index) => (
            <div
              className="dangerouslySetInnerHTML w-full text-blue-dark"
              key={index}
            >
              <div
                key={index}
                dangerouslySetInnerHTML={
                  { __html: column?.text || "" } as {
                    __html: string;
                  }
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CourseFeaturesModule;
