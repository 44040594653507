export const getLink = (url: string) => {
  if (url) {
    if (url?.includes("https://localhost:44396")) {
      return url.replace("https://localhost:44396", "");
    } else if (url.includes("http://api-lms-staging.melontech.com")) {
      return url.replace("http://api-lms-staging.melontech.com", "");
    } else if (url.includes("http://lms-staging.melontech.com")) {
      return url.replace("http://lms-staging.melontech.com", "");
    } else if (url.includes("https://staging.melonlearning.com")) {
      return url.replace("https://staging.melonlearning.com", "");
    } else if (url.includes("https://melonlearning.com")) {
      return url.replace("https://melonlearning.com", "");
    } else if (url.includes("https://umbraco-staging.melonlearning.com")) {
      return url.replace("https://umbraco-staging.melonlearning.com", "");
    } else if (url.includes("https://umbraco.melonlearning.com")) {
      return url.replace("https://umbraco.melonlearning.com", "");
    }else {
      return url;
    }
  } else {
    return "";
  }
};
