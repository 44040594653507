import { IContentModules } from "../../globalTypes";
import "./ImageGrid.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Keyboard } from 'swiper/modules';
import { useMediaQuery } from "usehooks-ts";
import { useMemo } from "react";
interface IProps {
  data: IContentModules;
}
const env = window._env_ ?? {}

const ImageGrid = ({ data }: IProps) => {
  const baseUrl = env.REACT_APP_IMG_BASE_URL;
  const isSmallScreen = useMediaQuery("(max-width: 1024px)");
  const imagesToRender = useMemo(() => {
    if (!data) return;

    if (isSmallScreen) return data.imagesMobile;

    return data.images;
  }, [isSmallScreen]);

  return (
    <div className="w-full xl:mx-auto xl:w-container">
      <div className="block lg:hidden">
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          grabCursor={true}
          pagination={{ clickable: true }}
          modules={[Pagination, Keyboard]}
          className='lg:w-[90%] w-full'
        >
          {imagesToRender?.map((img, index) => (
            img.url && (
              <SwiperSlide key={index}>
                <img
                  src={baseUrl + img?.url}
                  alt={img.altText}
                  className="h-[90%] w-full pb-[20px]"
                />
              </SwiperSlide>
            )
          ))}
        </Swiper>
      </div>
      <div className="hidden lg:block">
        <div className="grid-container">
          {data?.images?.map(
            (img, index) =>
              img.url && (
                <img
                  src={baseUrl + img?.url}
                  alt={img.altText}
                  className={`item${index + 1}`}
                  key={index}
                />
              ),
          )}
        </div>
      </div>
    </div>
  );
};
export default ImageGrid;
