import React from "react";
import Card from "../../components/Card/Card";
import { FeatureBlocks, IContentModules } from "../../globalTypes";
import LinkComp from "../../components/LinkComp/LinkComp";
import { customModuleStyle, darkButtonStyle } from "./CustomStyles";

interface IProps {
  data: IContentModules;
}
const env = window._env_ ?? {}

const SpecificFeaturesBlockModule = ({ data }: IProps) => {
  const baseUrl = env.REACT_APP_IMG_BASE_URL;
  return (
    <div
      className={`${customModuleStyle(data?.withBackground)} px-[10px] xl:px-0`}
    >
      <div className="rounded-3xl border border-primary-stroke p-6 lg:mx-auto xl:w-container xl:p-[50px]">
        <h2 className="pb-[10px] text-blue-dark font-roboto text-25 font-bold xl:pb-[30px] xl:text-40">
          {data?.heading}
        </h2>
        <p className="font-roboto text-20/26 text-gray xl:text-3xl">
          {data?.subHeading}
        </p>
        <div className="flex flex-col flex-wrap justify-between lg:flex-row">
          {data?.featureBlocks?.map((item: FeatureBlocks, index) => (
            <Card
              key={index}
              image={baseUrl + item?.icon?.url}
              customClassNames="xl:w-[520px] lg:w-[45%] mt-[30px] px-[28px] py-[30px] p-[30px] rounded-[40px]"
              customClassNameParagraph="lg:text-lg text-base"
              title={item.heading}
              paragraph={item.description}
              link={item.link}
            />
          ))}
        </div>
        <div className="mt-10">
          <div className="book-consultation-container dangerouslySetInnerHTML font-roboto text-gray-border">
            <div
              dangerouslySetInnerHTML={
                { __html: data?.description || "" } as { __html: string }
              }
            />
          </div>
          {data?.link && (
            <LinkComp
              linkUrl={data?.link?.url || ""}
              target={data?.link?.target}
              label={data?.link?.label}
              customStyle={`${darkButtonStyle} w-full px-8 py-2.5 lg:px-9 xl:w-60`}
              isExternal={data?.link?.isExternal}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SpecificFeaturesBlockModule;
