import { RouteLink } from "../globalTypes";
import instance from "../service/api.service";

const getRoutes = async () => {
  return await instance.get<RouteLink[]>("v1/content/routes").then((data) => {
    return data;
  });
};

export { getRoutes };
