import React, { useEffect, useState } from "react";
import { PlatformModule } from "../../components/svg/PlatformSVG/PlatformModule";
import { Minus } from "../../components/svg/PlatformSVG/Minus";
import { Plus } from "../../components/svg/PlatformSVG/Plus";
import { IAccordionItem, IContentModules } from "../../globalTypes";
import {
  customModuleStyle,
  featuredBlockModuleCustomOrder,
} from "./CustomStyles";

interface Prop {
  data?: IContentModules;
}

interface IsOpenObj extends IAccordionItem {
  index: number;
  isOpen: boolean;
}
const env = window._env_ ?? {}

const FeaturedBlockModule = ({ data }: Prop) => {
  const baseUrl = env.REACT_APP_IMG_BASE_URL;
  const [items, setItems] = useState<IsOpenObj[] | undefined>();

  useEffect(() => {
    if (data?.accordionItems && data?.accordionItems?.length > 0) {
      const sectionClosed = data?.accordionItems.map((item, index) => ({
        ...item,
        index,
        isOpen: false,
      }));

      setItems(sectionClosed);
    }
  }, [data]);

  return (
    <div className={`${customModuleStyle(data?.withBackground)} px-10 xl:px-0`}>
      <div className="w-full xl:mx-auto xl:w-container">
        {data?.mainHeading && (
          <div className="pb-[50px] text-2xl font-bold xl:text-50">
            {data?.mainHeading}
          </div>
        )}

        <div className={featuredBlockModuleCustomOrder(data?.isImageOnLeft)}>
          <div className="w-full lg:w-[46%]">
            <div className="pb-5 text-[25px] font-bold leading-[24px] xl:text-40 ">
              {data?.heading}
            </div>
            <div className="mb-5 text-base text-gray xl:mb-[50px] ">
              {data?.description}
            </div>
            {items?.length && items.length > 0 && (
              <div>
                {items?.map((item, index) => (
                  <div key={index} className="border-t border-primary-stroke">
                    <div
                      className="my-[25px] flex cursor-pointer justify-between  text-blue hover:text-blue-hover"
                      onClick={() => {
                        let newItem = { ...item, isOpen: false };
                        if (!item.isOpen) {
                          newItem = { ...item, isOpen: true };
                        }
                        const oldItems = [...items];
                        oldItems.splice(index, 1, newItem);
                        setItems(oldItems);
                      }}
                    >
                      <span className="text-xl font-bold">{item?.heading}</span>
                      {item.isOpen ? <div>{Minus}</div> : <div>{Plus}</div>}
                    </div>
                    {item.isOpen && (
                      <div className="pb-[30px]">{item.bodyText}</div>
                    )}
                  </div>
                ))}
                <div className="mb-[20px] border-b border-primary-stroke xl:mb-0"></div>
              </div>
            )}
          </div>
          <div className="w-full lg:w-1/2">
            {data?.image?.url ? (
              <img
                src={baseUrl + data.image?.url}
                width="100%"
                height="100%"
                alt={data?.image?.altText}
              />
            ) : (
              <PlatformModule />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedBlockModule;
