import React, { ChangeEventHandler } from "react";
import { textAreaStyle } from "../../pages/Modules/CustomStyles";
interface IProps {
  id: string;
  name: string;
  value: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  placeholder: string;
  label: string;
}
const TextArea = ({
  id,
  name,
  value,
  onChange,
  placeholder,
  label,
}: IProps) => {
  return (
    <div className="my-2 flex flex-col">
      <div className="mb-1 flex items-start text-base font-medium text-blue-dark">
        {label}
      </div>
      <textarea
        id={id}
        name={name}
        value={value}
        rows={3}
        className={textAreaStyle}
        placeholder={placeholder}
        onChange={onChange}
      ></textarea>
    </div>
  );
};

export default TextArea;
