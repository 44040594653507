import { AxiosResponse } from 'axios';
import { IApiResponse, IContentPage, IECourses } from '../../globalTypes';
import instance from '../../service/api.service';

const getContent = async (route: string) => {
  return await instance
    .get<IContentPage>(`/v1/content/page?url=${route}`)
    .then((data) => {
      return data;
    });
};

const getNextPageData = async (route?: string) => {
  return await instance.get<IECourses>(route || '').then((data) => {
    return data;
  });
};

const submitForm = async (values: FormData, route?: string) => {
  return await instance
    .post<FormData, AxiosResponse<IApiResponse>>(route || '', values)
    .then((data) => {
      return data;
    });
};

export { getContent, getNextPageData, submitForm };
