import { IFooter, IHeader, IImage } from "../../globalTypes";
import instance from "../../service/api.service";

export interface I404Resp {
  heading: string | undefined;
  subHeading: string | undefined;
  description: string | undefined;
  link: {
    url: string | undefined;
    target: string | undefined;
    label: string | undefined;
    isActive: string | undefined;
    isFile: string | undefined;
  };
  image: IImage;
  footer: IFooter;
  header: IHeader;
  id: number;
  pageType: string;
}

const getContent = async (route: string) => {
  return await instance
    .get<I404Resp>(`/v1/content/page?url=${route}`)
    .then((data) => {
      return data;
    });
};

export { getContent };
