import React, { useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { IContentPage } from "../../globalTypes";
import X from "../../components/svg/X.svg";
import IN from "../../components/svg/IN.svg";
import FB from "../../components/svg/FB.svg";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ContentModule from "./ContentModules";

interface IProps {
  data: IContentPage | null;
  pageTemplate: string;
}
const ContentPage = ({ data, pageTemplate }: IProps) => {
  useEffect(() => {
    const sectionId = window.location.href.split("#")[1];
    if (sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  });

  const customerStoryData = useMemo(() => {
    if (typeof data === "object") {
      return {
        summary: data?.summary,
        summaryLabel: data?.summaryLabel,
        additionalSummary: data?.additionalSummary,
        additionalSummaryLabel: data?.additionalSummaryLabel,
        useCaseLabel: data?.useCaseLabel,
        locationLabel: data?.locationLabel,
        industryLabel: data?.industryLabel,
        customerLogo: data?.customerLogo,
        customerName: data?.customerName,
      };
    }
  }, [data]);

  const hasCallToActionModule = useMemo(() => {
    if (typeof data === "object") {
      const hasCTAModule = data?.modules?.find(
        (m) => m.moduleName === "CallToActionModule",
      );
      return hasCTAModule ? true : false;
    }
  }, [data]);

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>{data?.metaProperties?.metaTitle}</title>
          <meta
            name="title"
            key="title"
            content={data?.metaProperties?.metaTitle}
          />
          <meta
            name="description"
            content={data?.metaProperties?.metaDescription}
          />
          <meta property="og:title" content={data?.metaProperties?.ogTitle} />
          <meta
            property="og:description"
            content={data?.metaProperties?.ogDescription}
          />
          <meta
            property="og:locale"
            key="og:locale"
            content={data?.metaProperties?.ogLocaleCurrent}
          />
          <meta
            property="og:image"
            key="og:image"
            content={data?.metaProperties?.ogImage?.url}
          />

          <script type="text/jsx">{data?.googleTagManagerHead}</script>
          <script type="text/jsx">{data?.metaPixelHead}</script>
        </Helmet>
        <script type="text/jsx">{data?.googleTagManagerBody}</script>
        {data?.header && <Header data={data.header} />}
        {
          <>
            {data?.hero && (
              <Hero data={data?.hero} pageTemplate={pageTemplate} />
            )}

            {(data?.category || data?.dateCreated) && (
              <div className="mx-[30px] my-[50px] flex flex-col justify-start text-xl text-gray-secondary  lg:mx-auto lg:w-[770px] lg:flex-row lg:items-center">
                {data?.category?.label && (
                  <div className="mr-4">{data?.category?.label}</div>
                )}
                {data?.category && data?.dateCreated && (
                  <div className="mr-4 hidden lg:block">|</div>
                )}
                {data?.dateCreated && (
                  <div className="mr-4">
                    {dayjs(data?.dateCreated).format("MMMM D, YYYY")}
                  </div>
                )}
              </div>
            )}

            {data?.tags && (
              <div className="mx-[30px] flex items-center justify-start  lg:mx-auto lg:w-[770px]">
                {data?.tags?.map((tag, index) => {
                  return (
                    <div
                      key={index}
                      className="mr-[10px] bg-blue-periwinkle p-[5px] text-base leading-[20px] text-blue-light"
                    >
                      {tag.label}
                    </div>
                  );
                })}
              </div>
            )}

            {data &&
              data?.modules &&
              data?.modules.slice()?.map((module, index) => {
                return (
                  <ContentModule
                    module={module}
                    key={index}
                    index={index}
                    customerStoryData={customerStoryData}
                    pageTemplate={pageTemplate}
                    pricingModelLabel={data?.pricingModelLabel}
                    pricingModelIcon={data?.pricingModelIcon}
                    languagesLabel={data?.languagesLabel}
                    languagesIcon={data?.languagesIcon}
                    interactivityLevelLabel={data?.interactivityLevelLabel}
                    interactivityLevelIcon={data?.interactivityLevelIcon}
                    durationLabel={data?.durationLabel}
                    durationIcon={data?.durationIcon}
                    industryLabel={data?.industryLabel}
                    industryIcon={data?.industryIcon}
                  />
                );
              })}

            {data?.shareLinks && (
              <div className="my-8 flex items-baseline justify-end px-4 lg:mx-auto lg:w-[770px]">
                {data?.shareLinks?.map((l, index) => (
                  <div key={index} className="p-4">
                    {l.type === "LinkedIn" && (
                      <Link to={l.url} target={l.target}>
                        <img src={IN} alt="LinkedIn" />
                      </Link>
                    )}
                    {l.type === "Twitter" && (
                      <Link to={l.url} target={l.target}>
                        <img src={X} alt="Twitter" />
                      </Link>
                    )}
                    {l.type === "Facebook" && (
                      <Link to={l.url} target={l.target}>
                        <img src={FB} alt="Facebook" />
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            )}

            {data?.footer && (
              <Footer
                data={data.footer}
                hasCallToActionModule={hasCallToActionModule}
              />
            )}
          </>
        }
        <script type="text/jsx">{data?.microsoftClarityBody}</script>
        <script type="text/jsx">{data?.cookieConsentScript}</script>
      </div>
    </HelmetProvider>
  );
};

export default ContentPage;
