import React from "react";

const Background2Image = () => {
  return (
    <svg
      className="3xl:ml-0 3xl:mt-0 absolute z-20  xl:w-0 2xl:-ml-[20px] 2xl:w-1/5"
      width="413"
      height="797"
      viewBox="0 0 413 797"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="542.966"
        cy="542.133"
        rx="542.966"
        ry="542.133"
        transform="matrix(0.321758 -0.946822 0.947831 0.318774 -964.63 661.581)"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="2"
      />
      <path
        d="M-754.515 141.861C-662.464 -129.789 -366.912 -275.567 -95.5305 -182.892C175.243 -91.4085 319.822 202.909 227.667 474.089C135.512 745.269 -160.134 890.347 -431.186 799.187C-702.237 708.027 -846.532 413.068 -754.515 141.861Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.926"
      />
      <path
        d="M-718.656 136.384C-631.122 -122.742 -348.873 -260.489 -89.8424 -171.546C167.361 -83.2326 304.846 196.717 217.108 454.902C129.369 713.088 -153.378 850.316 -411.44 763.524C-669.504 676.733 -806.12 394.625 -718.656 136.384Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.851"
      />
      <path
        d="M-682.796 130.908C-599.782 -115.695 -330.686 -245.856 -84.1538 -160.201C159.63 -75.5028 289.872 190.527 206.55 435.717C123.227 680.907 -146.621 810.284 -391.695 727.861C-636.77 645.437 -765.706 376.182 -682.796 130.908Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.777"
      />
      <path
        d="M-646.937 125.432C-568.441 -108.648 -312.503 -231.226 -78.4652 -148.856C151.896 -67.7805 274.897 184.336 195.99 416.53C117.083 648.726 -139.864 770.253 -371.95 692.198C-604.037 614.143 -725.292 357.739 -646.937 125.432Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.702"
      />
      <path
        d="M-611.076 119.955C-537.098 -101.6 -294.323 -216.6 -72.7751 -137.512C144.166 -60.0667 259.923 178.143 185.432 397.343C110.941 616.544 -133.106 730.222 -352.204 656.535C-571.3 582.849 -684.877 339.295 -611.076 119.955Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.628"
      />
      <path
        d="M-575.218 114.478C-505.759 -94.5537 -276.151 -201.98 -67.09 -126.167C136.431 -52.3641 244.946 171.951 174.872 378.156C104.797 584.363 -126.351 690.19 -332.459 620.872C-538.568 551.554 -644.465 320.852 -575.218 114.478Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.553"
      />
      <path
        d="M-539.358 109.002C-474.419 -87.5065 -257.986 -187.365 -61.4016 -114.821C128.695 -44.6714 229.971 165.76 164.312 358.972C98.653 552.182 -119.593 650.159 -312.714 585.209C-505.835 520.258 -604.053 302.409 -539.358 109.002Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.479"
      />
      <path
        d="M-503.498 103.527C-442.67 -80.324 -239.828 -172.757 -55.7125 -103.475C120.962 -36.9925 214.997 159.57 153.754 339.787C92.5115 520.003 -112.837 610.13 -292.969 549.548C-473.101 488.966 -563.24 284.098 -503.498 103.527Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.404"
      />
      <path
        d="M-467.638 98.0496C-411.279 -73.2629 -221.677 -158.159 -50.0238 -92.131C113.226 -29.3336 200.022 153.378 143.195 320.6C86.3679 487.822 -106.08 570.097 -273.222 513.884C-440.364 457.671 -522.78 265.668 -467.638 98.0496Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.33"
      />
      <path
        d="M-431.779 92.5733C-379.891 -66.2019 -203.539 -143.574 -44.3369 -80.785C105.491 -21.6954 185.047 147.186 132.636 301.414C80.2247 455.641 -99.3225 530.067 -253.477 478.222C-407.632 426.377 -482.322 247.239 -431.779 92.5733Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.255"
      />
      <path
        d="M-395.919 87.0979C-348.504 -59.14 -185.412 -129 -38.6486 -69.4391C97.7559 -14.0817 170.071 140.995 122.076 282.228C74.0811 423.461 -92.566 490.036 -233.732 442.559C-374.898 395.082 -441.865 228.809 -395.919 87.0979Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.181"
      />
      <path
        d="M-360.058 81.6205C-317.117 -52.0805 -167.303 -114.451 -32.9583 -58.0953C90.0215 -6.5077 155.098 134.803 111.519 263.041C67.9396 391.279 -85.808 450.004 -213.986 406.895C-342.163 363.787 -401.412 210.377 -360.058 81.6205Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.106"
      />
      <path
        d="M-324.2 76.1438C-285.732 -45.0219 -149.213 -99.9244 -27.2711 -46.7507C82.2871 1.02469 140.122 128.611 100.959 243.854C61.7963 359.097 -79.0532 409.973 -194.242 371.232C-309.43 332.493 -360.961 191.944 -324.2 76.1438Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="1.032"
      />
      <path
        d="M-288.34 70.6678C-254.351 -37.9659 -131.147 -85.4347 -21.5842 -35.4046C74.5589 8.49683 125.147 122.42 90.4002 224.668C55.6531 326.917 -72.2954 369.943 -174.496 335.571C-276.696 301.199 -320.516 173.508 -288.34 70.6678Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="0.957"
      />
      <path
        d="M-252.48 65.1911C-222.972 -30.9115 -113.108 -70.9952 -15.8949 -24.0599C66.8399 15.8861 110.173 116.228 79.8415 205.482C49.5104 294.735 -65.5384 329.911 -154.75 299.908C-243.962 269.904 -280.075 155.069 -252.48 65.1911Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="0.883"
      />
      <path
        d="M-216.62 59.7154C-191.601 -23.8601 -95.1034 -56.6333 -10.2064 -12.7143C59.1373 23.16 95.1972 110.037 69.2821 186.296C43.3671 262.555 -58.7818 289.88 -135.005 264.244C-211.228 238.609 -239.644 136.627 -216.62 59.7154Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="0.809"
      />
      <path
        d="M-180.759 54.2398C-160.238 -16.8148 -77.1335 -42.3925 -4.5189 -1.36771C51.4712 30.2628 80.2227 103.846 58.7237 167.11C37.2246 230.374 -52.0233 249.85 -115.259 228.582C-178.494 207.316 -199.226 118.18 -180.759 54.2398Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="0.734"
      />
      <path
        d="M-144.899 48.7622C-128.893 -9.78074 -59.1803 -28.3648 1.17036 9.97593C43.8752 37.1046 65.248 97.6529 48.165 147.922C31.0819 198.192 -45.2665 209.818 -95.5123 192.919C-145.758 176.02 -158.832 99.72 -144.899 48.7622Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="0.66"
      />
      <path
        d="M-109.041 43.2856C-97.5857 -2.761 -41.152 -14.7374 6.85785 21.3207C36.443 43.5408 50.273 91.4616 37.6059 128.736C24.9384 166.013 -38.5114 169.787 -75.7686 157.256C-113.026 144.726 -118.483 81.2452 -109.041 43.2856Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="0.585"
      />
      <path
        d="M-73.1806 37.8103C-66.3799 4.22696 -22.5487 -2.01611 12.547 32.6666C29.422 49.3449 35.2982 85.2709 27.0466 109.553C18.7955 133.833 -31.7542 129.755 -56.0228 121.593C-80.2914 113.431 -78.229 62.7419 -73.1806 37.8103Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="0.511"
      />
      <path
        d="M-37.3216 32.3334C-35.6417 11.1593 0.0128957 8.3816 18.233 44.012C23.6473 54.5986 20.3222 79.0788 16.4867 90.3655C12.6516 101.651 -24.9971 89.7245 -36.2772 85.9308C-47.5587 82.1366 -38.2626 44.2051 -37.3216 32.3334Z"
        stroke="#DDE0FF"
        strokeOpacity="0.5"
        strokeWidth="0.436"
      />
    </svg>
  );
};

export default Background2Image;
