import React from "react";
import { IContentModules } from "../../globalTypes";
import LinkComp from "../../components/LinkComp/LinkComp";
import {
  customModuleStyle,
  simpleBannerModuleCustomOrder,
  simpleBannerModuleImage,
} from "./CustomStyles";

interface IProps {
  data: IContentModules;
}
const env = window._env_ ?? {}

const SimpleBannerModule = ({ data }: IProps) => {
  const baseUrl = env.REACT_APP_IMG_BASE_URL;

  return (
    <div className={`${customModuleStyle(data?.withBackground)} px-10 xl:px-0`}>
      <div className="flex w-full flex-col-reverse items-center justify-between lg:flex-row xl:mx-auto xl:w-container">
        <div className={simpleBannerModuleCustomOrder(data?.isImageOnLeft)}>
          {data?.image?.url && (
            <div className={simpleBannerModuleImage(data?.isImageOnLeft)}>
              <img
                src={baseUrl + data?.image?.url}
                alt={data?.image?.altText}
              />
            </div>
          )}
          <div className="w-full lg:mx-auto lg:w-1/2 lg:pr-6">
            <div className="pb-5 text-25 font-bold text-blue-dark xl:text-40">
              {data?.heading}
            </div>
            <p className="text-gray-simple pb-5 text-[16px] leading-[26px]">
              {data?.description}
            </p>
            {data?.link?.url && baseUrl && (
              <div key={data?.link?.label} className="w-[120px]">
                <LinkComp
                  linkUrl={data?.link?.url || ""}
                  target={data?.link?.target}
                  label={data?.link?.label}
                  customStyle="read-more-btn flex items-center justify-start font-base font-medium text-blue"
                  customLabelStyle="read-more-blue-hover"
                  shouldShowArrow={true}
                  isExternal={data?.link?.isExternal}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleBannerModule;
