import React from "react";

const RadialLeftBottom = () => {
  return (
    <svg
      width="639"
      height="602"
      viewBox="0 0 639 602"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute z-30 hidden h-full overflow-hidden 2xl:block 2xl:w-[24%]"
    >
      <ellipse
        cx="554.487"
        cy="545.984"
        rx="554.487"
        ry="545.984"
        transform="matrix(0.342632 -0.915448 0.91645 0.328409 -914.379 619.209)"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M-689.517 106.275C-589.387 -161.949 -293.084 -302.162 -28.8686 -206.072C234.789 -111.172 367.056 183.132 266.839 450.889C166.623 718.645 -129.749 858.161 -393.692 763.579C-657.634 668.998 -789.599 374.06 -689.517 106.275Z"
        stroke="white"
        strokeWidth="1.926"
      />
      <path
        d="M-654.202 101.101C-558.956 -154.76 -276.037 -287.203 -23.8594 -195.004C226.539 -103.458 352.316 176.477 256.901 431.402C161.486 686.326 -121.906 818.246 -373.203 728.198C-624.502 638.149 -749.348 356.085 -654.202 101.101Z"
        stroke="white"
        strokeWidth="1.851"
      />
      <path
        d="M-618.89 95.9284C-528.53 -147.57 -258.833 -272.683 -18.854 -183.937C218.449 -96.1831 337.574 169.826 246.96 411.916C156.346 654.009 -114.066 778.331 -352.719 692.815C-591.374 607.3 -709.099 338.109 -618.89 95.9284Z"
        stroke="white"
        strokeWidth="1.777"
      />
      <path
        d="M-583.577 90.7553C-498.104 -140.381 -241.631 -258.166 -13.8475 -172.871C210.358 -88.9163 322.831 163.171 237.018 392.43C151.206 621.691 -106.225 738.417 -332.235 657.434C-558.245 576.451 -668.85 320.135 -583.577 90.7553Z"
        stroke="white"
        strokeWidth="1.702"
      />
      <path
        d="M-548.259 85.5811C-467.672 -133.191 -224.43 -243.653 -8.83673 -161.806C202.273 -81.658 308.092 156.516 227.081 372.943C146.07 589.371 -98.3806 698.502 -311.746 622.052C-525.111 545.603 -628.596 302.158 -548.259 85.5811Z"
        stroke="white"
        strokeWidth="1.628"
      />
      <path
        d="M-512.949 80.4075C-437.248 -126.002 -207.241 -229.147 -3.835 -150.739C194.18 -74.4107 293.346 149.861 217.137 353.456C140.926 557.053 -90.5438 658.587 -291.264 586.671C-491.985 514.754 -588.349 284.183 -512.949 80.4075Z"
        stroke="white"
        strokeWidth="1.553"
      />
      <path
        d="M-477.634 75.2341C-406.82 -118.813 -190.054 -214.646 1.17272 -139.673C186.089 -67.1739 278.604 143.207 207.195 333.972C135.787 524.735 -82.701 618.672 -270.778 551.288C-458.854 483.904 -548.1 266.208 -477.634 75.2341Z"
        stroke="white"
        strokeWidth="1.479"
      />
      <path
        d="M-442.321 70.0617C-375.997 -111.484 -172.877 -200.152 6.17922 -128.606C177.999 -59.9514 263.862 136.554 197.255 314.487C130.648 492.418 -74.8616 578.759 -250.294 515.908C-425.726 453.057 -507.461 248.368 -442.321 70.0617Z"
        stroke="white"
        strokeWidth="1.404"
      />
      <path
        d="M-407.006 64.8868C-345.519 -104.281 -155.705 -185.669 11.1869 -117.541C169.908 -52.748 249.12 129.898 187.314 294.999C125.508 460.1 -67.0209 538.843 -229.807 480.526C-392.592 422.208 -467.164 230.407 -407.006 64.8868Z"
        stroke="white"
        strokeWidth="1.33"
      />
      <path
        d="M-371.69 59.7132C-315.044 -97.0774 -138.544 -171.197 16.1935 -106.475C161.819 -45.5655 234.378 123.243 177.373 275.512C120.369 427.782 -59.1784 498.93 -209.321 445.145C-359.463 391.36 -426.869 212.446 -371.69 59.7132Z"
        stroke="white"
        strokeWidth="1.255"
      />
      <path
        d="M-336.382 54.5408C-284.577 -89.8728 -121.401 -156.738 21.1942 -95.4081C153.725 -38.4071 219.628 116.589 167.425 256.027C115.222 395.465 -51.3446 459.016 -188.842 409.763C-326.338 360.511 -386.581 194.485 -336.382 54.5408Z"
        stroke="white"
        strokeWidth="1.181"
      />
      <path
        d="M-301.063 49.3649C-254.102 -82.6724 -104.267 -142.305 26.2044 -84.3453C145.638 -31.2897 204.886 109.932 157.485 236.538C110.084 363.144 -43.5023 419.1 -168.354 374.38C-293.205 329.661 -346.289 176.519 -301.063 49.3649Z"
        stroke="white"
        strokeWidth="1.106"
      />
      <path
        d="M-265.752 44.1906C-223.632 -75.4719 -87.1541 -127.893 31.2077 -73.2809C137.55 -24.2128 190.139 103.277 147.54 217.052C104.94 330.826 -35.6669 379.185 -147.873 338.999C-260.077 298.813 -306.003 158.554 -265.752 44.1906Z"
        stroke="white"
        strokeWidth="1.032"
      />
      <path
        d="M-230.437 39.0159C-193.165 -68.2754 -70.0636 -113.52 36.2109 -62.2162C129.469 -17.197 175.391 96.6211 137.594 197.564C99.7958 298.508 -27.829 339.272 -127.389 303.618C-226.948 267.964 -265.721 140.585 -230.437 39.0159Z"
        stroke="white"
        strokeWidth="0.957"
      />
      <path
        d="M-195.128 33.8393C-162.707 -61.0818 -53.0046 -99.199 41.2101 -51.1546C121.393 -10.2648 160.637 89.9632 127.641 178.075C94.645 266.188 -19.9988 299.356 -106.912 268.235C-193.824 237.114 -225.449 122.612 -195.128 33.8393Z"
        stroke="white"
        strokeWidth="0.883"
      />
      <path
        d="M-159.814 28.6634C-132.251 -53.8918 -35.9723 -84.9561 46.2113 -40.0929C113.339 -3.44734 145.882 83.306 117.689 158.587C89.4952 233.869 -12.1666 259.441 -86.4316 232.852C-160.697 206.263 -185.18 104.636 -159.814 28.6634Z"
        stroke="white"
        strokeWidth="0.809"
      />
      <path
        d="M-124.498 23.4863C-101.801 -46.7098 -18.9696 -70.8358 51.211 -29.032C105.325 3.19915 131.126 76.6478 107.735 139.099C84.3442 201.55 -4.33379 219.528 -65.9511 197.471C-127.567 175.414 -144.922 86.6541 -124.498 23.4863Z"
        stroke="white"
        strokeWidth="0.734"
      />
      <path
        d="M-89.1871 18.3036C-71.3744 -39.5436 -1.98416 -56.9319 56.2025 -17.9784C97.3761 9.58391 116.356 69.9847 97.768 119.606C79.1804 169.228 3.48695 179.612 -45.4776 162.087C-94.4421 144.561 -104.692 68.6558 -89.1871 18.3036Z"
        stroke="white"
        strokeWidth="0.66"
      />
      <path
        d="M-53.8816 13.117C-40.9898 -32.3985 15.0803 -43.4307 61.179 -6.92935C89.5864 15.5639 101.566 63.3203 87.7827 100.114C73.9988 136.909 11.2937 139.697 -25.0157 126.702C-61.325 113.708 -64.5081 50.6388 -53.8816 13.117Z"
        stroke="white"
        strokeWidth="0.585"
      />
      <path
        d="M-18.5825 7.91734C-10.7133 -25.3041 32.7225 -30.8375 66.1268 4.10721C82.1885 20.9115 86.7381 56.6488 77.7604 80.6184C68.7833 104.587 19.0804 99.778 -4.56678 91.3127C-28.2139 82.8474 -24.4243 32.5803 -18.5825 7.91734Z"
        stroke="white"
        strokeWidth="0.511"
      />
      <path
        d="M16.7036 2.68454C19.0928 -18.3122 54.2963 -20.5281 70.9996 15.1236C75.9633 25.7166 71.8394 49.9706 67.671 61.118C63.503 72.2642 26.8465 59.8512 15.8714 55.9133C4.89485 51.9749 15.365 14.4568 16.7036 2.68454Z"
        stroke="white"
        strokeWidth="0.436"
      />
    </svg>
  );
};

export default RadialLeftBottom;
