import React from "react";
import Card from "../../components/Card/Card";
import { FeatureBlocks, IContentModules } from "../../globalTypes";
import { customModuleStyle } from "./CustomStyles";
interface IProp {
  data: IContentModules;
}
const env = window._env_ ?? {}

const FeaturesBlockModule = ({ data }: IProp) => {
  const baseUrl = env.REACT_APP_IMG_BASE_URL;

  return (
    <div
      className={`${customModuleStyle(data?.withBackground)} px-[10px] xl:px-0`}
    >
      <div className="rounded-3xl border border-gray-light px-[28px] py-[40px] lg:w-full lg:px-[40px] xl:mx-auto xl:w-container xl:py-[50px]">
        <div className="xl:text-40 text-25 pb-[30px] font-roboto font-bold text-blue-dark xl:pb-[50px] ">
          {data.heading}
        </div>
        <div className="grid grid-cols-1 gap-x-[30px] gap-y-[30px] md:grid-cols-2 lg:gap-y-[50px] ">
          {data?.featureBlocks?.map((item: FeatureBlocks, index) => (
            <Card
              key={index}
              customClassNames="bg-white p-[30px] rounded-[40px]"
              customClassNameParagraph="lg:text-lg text-base"
              image={baseUrl + item.icon.url}
              title={item.heading}
              paragraph={item.description}
              link={item.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturesBlockModule;
